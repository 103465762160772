import * as React from "react";
import gateway, { League } from "./apiGateway";
import { Link } from "react-router-dom";
import Loader from "./Components/Loader";
import { Table, Button } from "react-bootstrap";

const LeaguePage = () => {
  const [leagues, setLeagues] = React.useState<Array<League>>([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [currentError, setCurrentError] = React.useState<string>();

  React.useEffect(() => {
    (async () => {
      try {
        const leagues = await gateway.loadLeagues();
        setLeagues(leagues);
      } catch (err) {
        if (err instanceof Error) {
          setCurrentError(err.message);
        }
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  React.useEffect(() => {
    document.title = `MTG Results - Leagues`;
  }, []);

  return (
    <Loader isLoading={isLoading} error={currentError}>
      <React.Fragment>
        <h1>Leagues</h1>
        <Table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Format</th>
              <th>Start Date</th>
            </tr>
          </thead>
          <tbody>
            {leagues.length > 0 && (
              <React.Fragment>
                {leagues.map((l) => (
                  <tr key={l._id}>
                    <td>
                      <Link to={`/leagues/${l._id}`}>{l.name}</Link>
                    </td>
                    <td>{l.format}</td>
                    <td>{new Date(l.startDate).toLocaleDateString("en-US")}</td>
                  </tr>
                ))}
              </React.Fragment>
            )}
          </tbody>
        </Table>
        {!isLoading && leagues.length <= 0 && (
          <p>You are not currently invited to any leagues.</p>
        )}
        <Link to="/leagues/new">
          <Button>Add new League</Button>
        </Link>
      </React.Fragment>
    </Loader>
  );
};

export default LeaguePage;
