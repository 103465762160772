import * as React from "react";
// import Autosuggest from "./Components/AutoSuggest";
import { useSelector } from "react-redux";
import { collectionSelector } from "./redux/collectionSelectors";
// import AwesomeDebouncePromise from "awesome-debounce-promise";
import "./CollectionPage.css";
import { Card, Form, FormLabel, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import CollectionButtons from "./Components/CollectionButtons";

const CollectionPage = () => {
  // const [suggestions, setSuggestions] = useState([]);
  const collection = useSelector(collectionSelector);
  const [filterText, setFilterText] = React.useState("");
  const [selectedSetCode, setSelectedSetCode] = React.useState<string>();

  // const onSuggestionsFetchRequested = async ({ value }) => {
  //   if (!value) {
  //     onSuggestionsClearRequested();
  //     return;
  //   }
  //   const response = await fetch(
  //     `https://api.scryfall.com/cards/search?q=${value}&unique=prints&lang=en&digital=false`
  //   );
  //   if (response.ok) {
  //     const responseData = await response.json();

  //     setSuggestions(responseData.data);
  //   } else {
  //     onSuggestionsClearRequested();
  //   }
  // };
  // const asyncOnSuggetionsFetchRequest = AwesomeDebouncePromise(
  //   onSuggestionsFetchRequested,
  //   500
  // );
  // const onSuggestionsClearRequested = () => {
  //   setSuggestions([]);
  // };

  // const onGetValue = (card) => {
  //   return `${card.name} [${card.set.toUpperCase()}]`;
  // };

  // const onRender = (card) => {
  //   return (
  //     <div width="100%">
  //       {card.name}
  //       <span class="rightPart">{card.set_name}</span>
  //     </div>
  //   );
  // };

  let filteredCollection = collection;

  if (filterText) {
    filteredCollection = filteredCollection.filter((c) =>
      c.card_name.includes(filterText)
    );
  }

  if (selectedSetCode) {
    filteredCollection = filteredCollection.filter(
      (c) => c.set_code === selectedSetCode
    );
  }

  React.useEffect(() => {
    document.title = `MTG Results - Collection`;
  }, []);

  return (
    <React.Fragment>
      <CollectionButtons />
      {/* <Card>
            <Card.Body>
              <Card.Title>Add Card to Collection</Card.Title>
              <Card.Text>
                <Autosuggest
                  suggestions={suggestions}
                  onSuggestionsFetchRequested={asyncOnSuggetionsFetchRequest}
                  onSuggestionsClearRequested={onSuggestionsClearRequested}
                  onRender={onRender}
                  onGetValue={onGetValue}
                  placeholder="Type a card name"
                />
                <Button type="submit">Add</Button>
              </Card.Text>
            </Card.Body>
          </Card> */}
      <Card>
        <Card.Body>
          <Card.Title>Filter Collection</Card.Title>
          <Card.Text>
            <Form>
              <Form.Group>
                <FormLabel>Card</FormLabel>
                <Form.Control
                  type="text"
                  onChange={(e) => setFilterText(e.target.value)}
                  value={filterText}
                />
              </Form.Group>
              <Form.Group>
                <FormLabel>Set Code</FormLabel>
                <Form.Control
                  as="select"
                  value={selectedSetCode}
                  onChange={(e) => setSelectedSetCode(e.target.value)}
                >
                  <option value={undefined}></option>
                  {[...Array.from(new Set(collection.map((c) => c.set_code)))]
                    .filter(
                      (setCode) =>
                        setCode !== null &&
                        setCode !== undefined &&
                        setCode !== ""
                    )
                    .sort()
                    .map((setCode) => (
                      <option key={setCode}>{setCode}</option>
                    ))}
                </Form.Control>
              </Form.Group>
            </Form>
          </Card.Text>
        </Card.Body>
      </Card>
      <Table>
        <thead>
          <tr>
            <th>Quantity</th>
            <th>Card Name</th>
            <th>Set Code</th>
          </tr>
        </thead>
        <tbody>
          {filteredCollection.map((c, i) => (
            <tr key={i}>
              <td>{c.amount}</td>
              <td>
                <Link to={`/card/${encodeURIComponent(c.card_name)}`}>
                  {c.card_name}
                </Link>
              </td>
              <td>{c.set_code}</td>
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr>
            <th>Unique Cards</th>
            <td>{filteredCollection.length}</td>
            <th>Total Cards</th>
            <td>
              {filteredCollection
                .map((c) => c.amount)
                .reduce((a, b) => a + b, 0)}
            </td>
          </tr>
        </tfoot>
      </Table>
    </React.Fragment>
  );
};

export default CollectionPage;
