import { createSelector } from "reselect";
import { decksSelector, GlobalState } from "./reducer";
import {
  includeLeaguesSelector,
  includeDateSelector,
} from "./decksFeatureSelectors";
import { selectedArchetypeSelector, selectedEventSelector } from "./selectors";
import { reducedCollectionSelector } from "./collectionSelectors";
import { selectedDeckSelector } from "./selectors";
import {
  summarizeMetagame,
  summarizeMostPlayedCards,
  getDeckWithCollectionData,
  summarizeDeck,
  combineDecks,
  getNumberInCollection,
  Deck,
  EmptyDeck,
} from "../logic";

export const filteredDecksSelector = createSelector(
  decksSelector,
  includeLeaguesSelector,
  includeDateSelector,
  (decks, includeLeagues, date) =>
    decks.filter(
      (d) =>
        (includeLeagues || !d.event.toLowerCase().includes("league")) &&
        d.date?.valueOf() >= date.valueOf()
    )
);

export const formatMostPlayedCardsSelector = createSelector(
  filteredDecksSelector,
  (decks) => summarizeMostPlayedCards(decks)
);

export const deckSelector = createSelector(
  decksSelector,
  (state: GlobalState, params: { id: string }) =>
    selectedDeckSelector(state, params),
  reducedCollectionSelector,
  (decks, selectedDeck, collection) => {
    const deck = decks.find((d) => d._id === selectedDeck.id);
    if (deck !== undefined && deck !== null) {
      return getDeckWithCollectionData(deck, collection);
    } else {
      return {
        _id: "a",
        owner: "a",
        archetype: "a",
        event: "a",
        date: new Date(),
        place: "a",
        format: "a",
        mainboard: [],
        sideboard: [],
        totalCardsNeeded: 0,
        name: "a",
      };
    }
  }
);

export const deckSelector2 = createSelector(
  (_: GlobalState, selectedDeck: Deck | undefined) =>
    selectedDeck || new EmptyDeck(),
  reducedCollectionSelector,
  (deck, collection) => getDeckWithCollectionData(deck, collection)
);

export const archetypeDecksSelector = createSelector(
  filteredDecksSelector,
  (state: GlobalState, params: { archetype: string }) =>
    selectedArchetypeSelector(state, params),
  (decks: Array<Deck>, archetype: string) =>
    decks
      .filter((d) => d.archetype === archetype)
      .sort((a, b) => b.date.valueOf() - a.date.valueOf())
);

export const selectedEventDecksSelector = createSelector(
  [
    decksSelector,
    (state: GlobalState, params: { event: string; date: string }) =>
      selectedEventSelector(state, params),
  ],
  (decks: Array<Deck>, selectedEvent: { name: string; date: Date }) =>
    decks.filter(
      (d) =>
        d.event === selectedEvent.name &&
        d.date.valueOf() === selectedEvent.date.valueOf()
    )
);

export const eventsSelector = createSelector(
  filteredDecksSelector,
  (decks: Array<{ event: string; date: Date }>) =>
    decks
      .map((d) => ({
        event: d.event,
        date: d.date,
      }))
      .reduce((a, v) => {
        if (
          !a.find(
            (b) => b.event === v.event && b.date.valueOf() === v.date.valueOf()
          )
        ) {
          a.push(v);
        }
        return a;
      }, new Array<{ event: string; date: Date }>())
      .sort((a, b) => b.date.valueOf() - a.date.valueOf())
);

export const eventMetagameSummarySelector = createSelector(
  (state: GlobalState, params: { event: string; date: string }) =>
    selectedEventDecksSelector(state, params),
  (decks) => summarizeMetagame(decks)
);

export const formatMetagameSummarySelector = createSelector(
  filteredDecksSelector,
  (decks) => summarizeMetagame(decks)
);

export const neededCardsSelector = createSelector(
  reducedCollectionSelector,
  (state: GlobalState, params: { archetype: string }) =>
    archetypeDecksSelector(state, params),
  (reducedCollection, filteredDecks: Array<Deck>) => {
    const cards = combineDecks(filteredDecks.map((d) => summarizeDeck(d))).sort(
      (a, b) => b.percentageOfDecks - a.percentageOfDecks
    );

    return cards.map((c) => {
      const numberInCollection = getNumberInCollection(
        reducedCollection,
        c.name
      );

      return {
        name: c.name,
        percentageOfDecks: (c.percentageOfDecks * 100).toFixed(2) + "%",
        maxcount: c.maxcount,
        numberInCollection: numberInCollection,
        numberNeeded: c.maxcount - numberInCollection,
      };
    });
  }
);

export const eventMostPlayedCardsSelector = createSelector(
  (state: GlobalState, params: { event: string; date: string }) =>
    selectedEventDecksSelector(state, params),
  (decks) => summarizeMostPlayedCards(decks)
);
