import * as React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import CSS from "csstype";
import { PinAngle, PinAngleFill } from "react-bootstrap-icons";

interface PinnableProps {
  children: JSX.Element | JSX.Element[];
}

const Pinnable = ({ children }: PinnableProps) => {
  const [position, setPosition] =
    React.useState<CSS.Property.Position>("relative");
  return (
    <React.Fragment>
      {position === "fixed" ? (
        <OverlayTrigger
          placement="right"
          delay={{ show: 250, hide: 400 }}
          overlay={(props) => (
            <Tooltip id="unpin" {...props}>
              UnPin
            </Tooltip>
          )}
        >
          <PinAngleFill
            style={{ cursor: "pointer" }}
            onClick={() => setPosition("relative")}
          />
        </OverlayTrigger>
      ) : (
        <OverlayTrigger
          placement="right"
          delay={{ show: 250, hide: 400 }}
          overlay={(props) => (
            <Tooltip id="pin" {...props}>
              Pin
            </Tooltip>
          )}
        >
          <PinAngle
            style={{ cursor: "pointer" }}
            onClick={() => setPosition("fixed")}
          />
        </OverlayTrigger>
      )}
      <div style={{ position: position }}>{children}</div>
    </React.Fragment>
  );
};

export default Pinnable;
