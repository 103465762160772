import { Route } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

interface PrivateRouteProps {
  exact: boolean;
  children: JSX.Element | JSX.Element[];
  message: string;
  path: string;
}

const PrivateRoute = ({
  children,
  message,
  exact,
  path,
  ...rest
}: PrivateRouteProps) => {
  const { isAuthenticated } = useAuth0();
  return (
    <Route
      {...rest}
      render={() => (isAuthenticated ? children : <p>{message}</p>)}
    />
  );
};

export default PrivateRoute;
