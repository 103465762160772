import { Placeholder } from "react-bootstrap";

interface SkeletonProps {
  count?: number;
}

const Skeleton = ({ count = 20 }: SkeletonProps) => (
  <>
    {[...Array(count)].map((_: number) => (
      <Placeholder as="span" animation="wave">
        <Placeholder xs={12} />
      </Placeholder>
    ))}
  </>
);

export default Skeleton;
