import { createSelector } from "reselect";

import { CollectionCard, reduceCollection } from "../logic";
import { collectionsSelector } from "./reducer";
import { selectedCollectionNameSelector } from "./collectionFeatureSelectors";

export const collectionSelector = createSelector(
  collectionsSelector,
  selectedCollectionNameSelector,
  (collections, selectedCollectionName) => {
    if (collections?.length > 0) {
      return (
        collections.find((c) => c.name === selectedCollectionName)?.cards || []
      );
    }

    return new Array<CollectionCard>();
  }
);

export const reducedCollectionSelector = createSelector(
  collectionSelector,
  (collection) => reduceCollection(collection)
);
