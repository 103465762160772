import * as React from "react";
import { Link } from "react-router-dom";
import { Table } from "react-bootstrap";
import Skeleton from "./Skeleton";

import type { Deck } from "../logic";

type DecksProps = {
  isLoading: boolean;
  decks: Array<Deck>;
  selectedDeck?: Deck;
  onDeckSelected: (deck: Deck) => void;
  type?: "small" | "big";
  showFormat?: boolean;
};

const Decks = ({
  isLoading,
  decks,
  onDeckSelected,
  type = "big",
  selectedDeck,
  showFormat = false,
}: DecksProps) => {
  return (
    <React.Fragment>
      <h2>{!isLoading && <span>{decks.length}</span>} Decks</h2>
      <Table>
        <thead>
          <tr>
            {showFormat && <th>Format</th>}
            <th>Result</th>
            <th>Archetype</th>
            <th>Owner</th>
            {type === "big" && (
              <React.Fragment>
                <th>Event</th>
                <th>Date</th>
              </React.Fragment>
            )}
          </tr>
        </thead>
        <tbody>
          {isLoading && (
            <tr>
              {showFormat && (
                <td>
                  <Skeleton count={20} />
                </td>
              )}
              <td>
                <Skeleton count={20} />
              </td>
              <td>
                <Skeleton count={20} />
              </td>
              <td>
                <Skeleton count={20} />
              </td>
              {type === "big" && (
                <React.Fragment>
                  <td>
                    <Skeleton count={20} />
                  </td>
                  <td>
                    <Skeleton count={20} />
                  </td>
                </React.Fragment>
              )}
            </tr>
          )}
          {!isLoading &&
            decks.map((d, index) => (
              <tr
                onClick={() => onDeckSelected(d)}
                key={index}
                className={selectedDeck === d ? "selected" : ""}
              >
                {showFormat && <td>{d.format}</td>}
                <td>{d.place}</td>
                <td>
                  <Link to={`/${d.format}/archetype/${d.archetype}`}>
                    {d.archetype}
                  </Link>
                </td>
                <td>
                  <Link to={`/player/${d.owner}`}>{d.owner}</Link>
                </td>
                {type === "big" && (
                  <React.Fragment>
                    <td>
                      {
                        //TODO: use generatePath
                      }
                      <Link
                        to={`/${d.format}/event/${encodeURIComponent(
                          d.event
                        )}/${d.date.valueOf()}`}
                      >
                        {d.event}
                      </Link>
                    </td>
                    <td>{d.date.toLocaleDateString("en-US")}</td>
                  </React.Fragment>
                )}
              </tr>
            ))}
        </tbody>
      </Table>
    </React.Fragment>
  );
};

export default Decks;
