import { useFormik } from "formik";
import { DeckWithCollectionData } from "../logic";
import { updateDeck } from "../redux/decksReducer";
import { useDispatch } from "react-redux";
import { Button, Form } from "react-bootstrap";
import * as Yup from "yup";

interface EditArchetypeFormProps {
  deck: DeckWithCollectionData;
}

const EditArchetypeForm = ({ deck }: EditArchetypeFormProps) => {
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: { archetype: deck.archetype },
    onSubmit: (values) => {
      dispatch(updateDeck(deck._id, values));
    },
    validationSchema: Yup.object({
      archetype: Yup.string()
        .max(50, "Must be 50 characters or less")
        .required("Required"),
    }),
  });

  return (
    <Form onSubmit={formik.handleSubmit}>
      <Form.Group>
        <Form.Label>Archetype</Form.Label>
        <Form.Control
          type="text"
          name="archetype"
          onChange={formik.handleChange}
          value={formik.values.archetype}
          isInvalid={formik.errors.archetype ? true : false}
        />
        <Form.Control.Feedback type="invalid">
          {formik.errors.archetype}
        </Form.Control.Feedback>
      </Form.Group>
      <Button type="submit">Update Archetype</Button>
    </Form>
  );
};

export default EditArchetypeForm;
