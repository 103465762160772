import * as React from "react";
import { useDispatch } from "react-redux";
import axios from "axios";
import csv from "csvtojson";
import { loadCollection } from "./redux/collectionReducer";
import { useAuth0 } from "@auth0/auth0-react";

const UpdatePaperPage = () => {
  const dispatch = useDispatch();
  const [isUploading, setIsUploading] = React.useState(false);
  const [filename, setFilename] = React.useState<string>();
  const [errorMessage, setErrorMessage] = React.useState<string>();
  const [wasSuccess, setWasSuccess] = React.useState<boolean>();
  const { user } = useAuth0();

  async function updatePaper(file: File, source: string) {
    const baseUrl = process.env["REACT_APP_API_URL"] ?? "";
    if (baseUrl === "") {
      throw new Error("Missing REACT_APP_API_URL");
    }

    setFilename(file.name);
    setWasSuccess(false);
    setErrorMessage(undefined);

    const userId = user?.sub;

    if (!file || !userId) {
      return;
    }
    var reader = new FileReader();

    reader.onload = async function (evt) {
      var csvData = evt?.target?.result?.toString() ?? "";

      const jsonArray = await csv({ output: "csv" }).fromString(csvData);

      const paperCards =
        source === "deckstats"
          ? jsonArray.map((j) => ({
              card_name: j[1],
              set_code: j[6],
              amount: parseInt(j[0]),
            }))
          : jsonArray.map((j) => ({
              card_name: j[2],
              set_code: j[3],
              amount: parseInt(j[0]),
            }));

      //TODO: move to apiGateway
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      };

      await axios.post(
        `${baseUrl}/users/${userId}/collections/updatepapercollection`,
        paperCards,
        {
          headers: headers,
        }
      );
      setWasSuccess(true);
      setIsUploading(false);
      dispatch(loadCollection(userId));
    };

    reader.onerror = function (evt) {
      setIsUploading(false);
      setErrorMessage("There was a problem uploading the file");
      console.error("An error ocurred reading the file", evt);
    };

    setIsUploading(true);
    reader.readAsText(file);
  }

  function onSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    var formData = new FormData(event.target as HTMLFormElement);
    var file = formData.get("file") as File;
    var source = formData.get("source") as string;
    updatePaper(file, source);
  }

  return (
    <div>
      {wasSuccess && <span>Uploaded Successfully.</span>}
      {errorMessage && <span>{errorMessage}</span>}
      {isUploading && <div>Loading {filename}...</div>}
      {!isUploading && (
        <div>
          <p>Select your Paper collection file</p>
          <form id="upload" name="upload" onSubmit={onSubmit}>
            <input type="file" id="filetoRead" name="file" />
            <label htmlFor="source">Source of file</label>
            <select id="source" name="source">
              <option value="moxfield">Moxfield</option>
              <option value="deckstats">Deck Stats</option>
            </select>
            <input
              type="submit"
              id="uploadButton"
              name="uploadButton"
              value="upload"
            />
          </form>
        </div>
      )}
      <div>
        <h3>Example File:</h3>
        <code>
          amount,card_name,is_foil,is_pinned,set_id,set_code,collector_number,language,condition,comment,added
          <br />
          4,"Abandon Reason",,,"","EMN","","","","",""
          <br />
          4,"Abbey Gargoyles",,,"","HM","","","","",""
          <br />
          4,"Abbey Matron",,,"","HM","","","","",""
          <br />
        </code>
        <h3>Instructions</h3>
        <ul>
          <li>
            The file must have a header row, but the contents do not matter
          </li>
          <li>
            Only the 1st, 2nd and 6th fields are currently read and the position
            is important
          </li>
          <li>
            the 1st column should be an integer, the 2nd and 6th should be
            strings
          </li>
        </ul>
      </div>
    </div>
  );
};

export default UpdatePaperPage;
