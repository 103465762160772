import * as React from "react";
import gateway from "./apiGateway";
import { useParams, useHistory } from "react-router-dom";
import Loader from "./Components/Loader";
import { Button, Form, Spinner, Alert } from "react-bootstrap";

const LeagueAddMatchResultsPage = () => {
  const { leagueId, matchId } = useParams<{
    leagueId: string;
    matchId: string;
  }>();
  const [match, setMatch] = React.useState<{
    player1Name: string;
    player2Name: string;
  }>();
  const [isLoading, setIsLoading] = React.useState(true);
  const [currentError, setCurrentError] = React.useState<string>();
  const [isSaving, setIsSaving] = React.useState(false);
  const [player1Wins, setPlayer1Wins] = React.useState(0);
  const [player2Wins, setPlayer2Wins] = React.useState(0);
  const [errorAlertText, setErrorAlertText] = React.useState<string>();
  const history = useHistory();

  React.useEffect(() => {
    (async () => {
      try {
        const matchFromApi = await gateway.loadLeagueMatch(leagueId, matchId);
        console.dir(matchFromApi);
        setMatch(matchFromApi);
        document.title = `MTG Results - ${match?.player1Name} vs ${match?.player2Name}`;
      } catch (err) {
        if (err instanceof Error) {
          setCurrentError(err.message);
        }
      } finally {
        setIsLoading(false);
      }
    })();
  }, [leagueId, matchId, match?.player1Name, match?.player2Name]);

  const save = async () => {
    setIsSaving(true);
    try {
      const matchResults = {
        player1Wins: player1Wins,
        player2Wins: player2Wins,
      };
      await gateway.saveMatchResults(leagueId, matchId, matchResults);
      history.push(`/leagues/${leagueId}/matches`);
    } catch (err) {
      if (err instanceof Error) {
        setErrorAlertText(err.message);
      }
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <Loader isLoading={isLoading} error={currentError}>
      {errorAlertText ? (
        <Alert
          variant="danger"
          onClose={() => setErrorAlertText(undefined)}
          dismissible
        >
          <Alert.Heading>Could not save match results</Alert.Heading>
          {errorAlertText}
        </Alert>
      ) : (
        <div />
      )}
      <h1>Record Match Results</h1>
      <Form>
        <Form.Group>
          <Form.Label>{match?.player1Name} Wins</Form.Label>
          <Form.Control
            as="select"
            defaultValue={player1Wins}
            onChange={(e) => setPlayer1Wins(parseInt(e.target.value))}
          >
            <option>0</option>
            <option>1</option>
            <option>2</option>
          </Form.Control>
        </Form.Group>
        <Form.Group>
          <Form.Label>{match?.player2Name} Wins</Form.Label>
          <Form.Control
            as="select"
            defaultValue={player2Wins}
            onChange={(e) => setPlayer2Wins(parseInt(e.target.value))}
          >
            <option>0</option>
            <option>1</option>
            <option>2</option>
          </Form.Control>
        </Form.Group>
        <Button onClick={() => save()} disabled={isSaving}>
          {!isSaving && <React.Fragment>Save Results</React.Fragment>}
          {isSaving && (
            <React.Fragment>
              <Spinner
                as="span"
                animation="grow"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              Saving...
            </React.Fragment>
          )}
        </Button>
      </Form>
    </Loader>
  );
};

export default LeagueAddMatchResultsPage;
