import {
  Popover,
  OverlayTrigger,
  Button,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import { BoxArrowInRight, BoxArrowRight } from "react-bootstrap-icons";
import { useAuth0 } from "@auth0/auth0-react";
import { useDispatch } from "react-redux";
import { loadCollection } from "../redux/collectionReducer";
import * as React from "react";

const SignIn = () => {
  const dispatch = useDispatch();
  const {
    loginWithPopup,
    isAuthenticated,
    logout,
    user,
    getAccessTokenSilently,
  } = useAuth0();

  React.useEffect(() => {
    (async () => {
      const accessToken = await getAccessTokenSilently();
      localStorage.setItem("token", accessToken);
      dispatch(loadCollection(user?.sub ?? ""));
    })();
  }, [user, dispatch, getAccessTokenSilently]);

  return (
    <div>
      {isAuthenticated ? (
        <div>
          <OverlayTrigger
            trigger="click"
            placement="bottom"
            overlay={
              <Popover id="user_profile">
                <Popover.Header as="h3">User Profile</Popover.Header>
                <Popover.Body>
                  <Container fluid>
                    <Row>
                      <Col>
                        <div>name: {user?.name}</div>
                        <div>user id: {user?.sub}</div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>&nbsp;</Col>
                    </Row>
                    <Row>
                      <Col className="text-right">
                        <Button
                          variant="light"
                          onClick={() =>
                            logout({ returnTo: window.location.origin })
                          }
                        >
                          <BoxArrowRight /> Sign Out
                        </Button>
                      </Col>
                    </Row>
                  </Container>
                </Popover.Body>
              </Popover>
            }
          >
            <img
              style={{ cursor: "pointer" }}
              height="40"
              width="40"
              src={user?.picture}
              alt={user?.name}
            />
          </OverlayTrigger>
        </div>
      ) : (
        <Button variant="light" onClick={() => loginWithPopup()}>
          <BoxArrowInRight /> Sign In
        </Button>
      )}
    </div>
  );
};

export default SignIn;
