import * as React from "react";
import { Popover } from "react-bootstrap";
import scryfallGateway, { ScryfallCard } from "../scryfallGateway";

interface CardDisplayProps {
  selectedCard: string;
}

export const CardDisplay = ({ selectedCard }: CardDisplayProps) => {
  const [cardData, setCardData] = React.useState<ScryfallCard | null>();

  React.useEffect(() => {
    if (selectedCard) {
      (async () => {
        const cardData = await scryfallGateway.getCard(selectedCard);
        setCardData(cardData);
      })();
    }
  }, [selectedCard]);

  return (
    <Popover id="card_image">
      <Popover.Body>
        <img
          src={
            cardData?.image_uris?.small ||
            cardData?.card_faces[0]?.image_uris?.small
          }
          alt={cardData?.name}
        />
      </Popover.Body>
    </Popover>
  );
};
