import { createSelector } from "reselect";
import { collectionFeatureSelector } from "./reducer";

export const isLoadingCollectionSelector = createSelector(
  collectionFeatureSelector,
  (collectionFeature) => collectionFeature.isLoading ?? false
);

export const selectedCollectionNameSelector = createSelector(
  collectionFeatureSelector,
  (collectionFeature) => collectionFeature.selectedCollectionName
);
