import { Dispatch } from "redux";
import apiGateway from "../apiGateway";
import type { Deck } from "../logic";

export const FINISH_LOAD_DECKS = "FINISH_LOAD_DECKS";
export const ERROR_LOAD_DECKS = "ERROR_LOAD_DECKS";
export const BEGIN_LOAD_DECKS = "BEGIN_LOAD_DECKS";
export const FINISH_LOAD_DECK = "FINISH_LOAD_DECK";
export const ERROR_LOAD_DECK = "ERROR_LOAD_DECK";
export const BEGIN_LOAD_DECK = "BEGIN_LOAD_DECK";
export const BEGIN_LOAD_EVENT_DECKS = "BEGIN_LOAD_EVENT_DECKS";
export const FINISH_LOAD_EVENT_DECKS = "FINISH_LOAD_EVENT_DECKS";
export const ERROR_LOAD_EVENT_DECKS = "ERROR_LOAD_EVENT_DECKS";
export const BEGIN_LOAD_ARCHETYPE_DECKS = "BEGIN_LOAD_ARCHETYPE_DECKS";
export const FINISH_LOAD_ARCHETYPE_DECKS = "FINISH_LOAD_ARCHETYPE_DECKS";
export const ERROR_LOAD_ARCHETYPE_DECKS = "ERROR_LOAD_ARCHETYPE_DECKS";
export const BEGIN_DELETE_DECK = "BEGIN_DELETE_DECK";
export const FINISH_DELETE_DECK = "FINISH_DELETE_DECK";
export const ERROR_DELETE_DECK = "ERROR_DELETE_DECK";
export const BEGIN_UPDATE_DECK = "BEGIN_UPDATE_DECK";
export const FINISH_UPDATE_DECK = "FINISH_UPDATE_DECK";
export const ERROR_UPDATE_DECK = "ERROR_UPDATE_DECK";

const beginUpdateDeck = () => ({ type: BEGIN_UPDATE_DECK });
const finishUpdateDeck = (id: string, updates: object) => ({
  type: FINISH_UPDATE_DECK,
  payload: { id: id, updates: updates },
});
const errorUpdateDeck = (error: string) => ({
  type: ERROR_UPDATE_DECK,
  payload: error,
});

export const updateDeck =
  (id: string, updates: object) => async (dispatch: Dispatch) => {
    dispatch(beginUpdateDeck());
    try {
      await apiGateway.updateDeck(id, updates);
      dispatch(finishUpdateDeck(id, updates));
    } catch (error) {
      console.error(error);
      dispatch(errorUpdateDeck("Error updating deck"));
    }
  };

const beginDeleteDeck = () => ({ type: BEGIN_DELETE_DECK });
const finishDeleteDeck = (id: string) => ({
  type: FINISH_DELETE_DECK,
  payload: id,
});
const errorDeleteDeck = (error: string) => ({
  type: ERROR_DELETE_DECK,
  payload: error,
});

export const deleteDeck = (id: string) => async (dispatch: Dispatch) => {
  dispatch(beginDeleteDeck());
  try {
    await apiGateway.deleteDeck(id);
    dispatch(finishDeleteDeck(id));
  } catch (error) {
    console.error(error);
    dispatch(errorDeleteDeck("Error deleting deck"));
  }
};

type FinishLoadArchetypeDecksAction = {
  type: typeof FINISH_LOAD_ARCHETYPE_DECKS;
  payload: {
    archetype: string;
    format: string;
    decks: Deck[];
  };
};

type FinishLoadEventDecksAction = {
  type: typeof FINISH_LOAD_EVENT_DECKS;
  payload: {
    event: string;
    date: Date;
    format: string;
    decks: Deck[];
  };
};

type FinishLoadDecksAction = {
  type: typeof FINISH_LOAD_DECKS;
  payload: {
    decks: Deck[];
  };
};

type FinishLoadDeckAction = {
  type: typeof FINISH_LOAD_DECK;
  payload: {
    deck: Deck;
  };
};

type FinishDeleteDeckAction = {
  type: typeof FINISH_DELETE_DECK;
  payload: { deck: Deck };
};

type FinishUpdateDeckAction = {
  type: typeof FINISH_UPDATE_DECK;
  payload: { id: string; updates: Deck };
};

type DeckAction =
  | FinishLoadArchetypeDecksAction
  | FinishLoadEventDecksAction
  | FinishLoadDecksAction
  | FinishLoadDeckAction
  | FinishDeleteDeckAction
  | FinishUpdateDeckAction;

export const decksReducer = (state: Array<Deck> = [], action: DeckAction) => {
  switch (action.type) {
    case FINISH_LOAD_ARCHETYPE_DECKS:
      return [
        ...state.filter(
          (d) =>
            !(
              d.archetype === action.payload.archetype &&
              d.format === action.payload.format
            )
        ),
        ...action.payload.decks,
      ];
    case FINISH_LOAD_EVENT_DECKS:
      return [
        ...state.filter(
          (d) =>
            !(
              d.event === action.payload.event &&
              d.date.valueOf() === action.payload.date.valueOf() &&
              d.format === action.payload.format
            )
        ),
        ...action.payload.decks,
      ];
    case FINISH_LOAD_DECKS:
      return action.payload.decks;
    case FINISH_LOAD_DECK:
      return [
        ...state.filter((d) => d._id !== action.payload.deck._id),
        action.payload.deck,
      ];
    case FINISH_DELETE_DECK:
      return state.filter((d) => d._id !== action.payload.deck._id);
    case FINISH_UPDATE_DECK:
      const existingDeck = state.find((d) => d._id === action.payload.id);
      const newDeck = {
        ...existingDeck,
        ...action.payload.updates,
      };
      return [...state.filter((d) => d._id !== action.payload.id), newDeck];
    default:
      return state;
  }
};

const beginLoadDeck = () => ({ type: BEGIN_LOAD_DECK });
const finishLoadDeck = (deck: Deck) => ({
  type: FINISH_LOAD_DECK,
  payload: { deck },
});
const errorLoadDeck = (error: string) => ({
  type: ERROR_LOAD_DECK,
  payload: error,
});

export const loadDeck = (id: string) => async (dispatch: Dispatch) => {
  dispatch(beginLoadDeck());
  try {
    const deck = await apiGateway.loadDeck(id);
    dispatch(finishLoadDeck(deck));
  } catch (error) {
    console.error(error);
    dispatch(errorLoadDeck("Error retrieving deck"));
  }
};

const beginLoadDecks = () => ({ type: BEGIN_LOAD_DECKS });
const finishLoadDecks = (
  format: string,
  startDate: Date,
  decks: Array<Deck>
) => ({
  type: FINISH_LOAD_DECKS,
  payload: { format, startDate, decks },
});
const errorLoadDecks = (error: string) => ({
  type: ERROR_LOAD_DECKS,
  payload: error,
});

export const loadDecks =
  (format: string, startDate: Date) => async (dispatch: Dispatch) => {
    dispatch(beginLoadDecks());
    try {
      const decks = await apiGateway.loadDecksByDate(format, startDate);
      dispatch(finishLoadDecks(format, startDate, decks));
    } catch (error) {
      console.error(error);
      dispatch(errorLoadDecks("Error retrieving decks"));
    }
  };

const beginLoadArchetypeDecks = () => ({ type: BEGIN_LOAD_ARCHETYPE_DECKS });
const finishLoadArchetypeDecks = (
  archetype: string,
  format: string,
  decks: Array<Deck>
) => ({
  type: FINISH_LOAD_ARCHETYPE_DECKS,
  payload: {
    archetype,
    format,
    decks,
  },
});
const errorLoadArchetypeDecks = (error: string) => ({
  type: ERROR_LOAD_ARCHETYPE_DECKS,
  payload: error,
});

export const loadArchetypeDecks =
  (archetype: string, format: string) => async (dispatch: Dispatch) => {
    dispatch(beginLoadArchetypeDecks());
    try {
      const decks = await apiGateway.loadArchetypeDecks(archetype, format);
      dispatch(finishLoadArchetypeDecks(archetype, format, decks));
    } catch (error) {
      console.error(error);
      dispatch(errorLoadArchetypeDecks("Error retrieving archetype decks"));
    }
  };

const beginLoadEventDecks = () => ({ type: BEGIN_LOAD_EVENT_DECKS });
const finishLoadEventDecks = (
  event: string,
  date: Date,
  format: string,
  decks: Array<Deck>
) => ({
  type: FINISH_LOAD_EVENT_DECKS,
  payload: {
    event,
    date,
    format,
    decks,
  },
});
const errorLoadEventDecks = (error: string) => ({
  type: ERROR_LOAD_EVENT_DECKS,
  payload: error,
});

export const loadEventDecks =
  (event: string, date: Date, format: string) => async (dispatch: Dispatch) => {
    dispatch(beginLoadEventDecks());
    try {
      const decks = await apiGateway.loadEventDecks(event, date, format);
      dispatch(finishLoadEventDecks(event, date, format, decks));
    } catch (error) {
      console.error(error);
      dispatch(errorLoadEventDecks("Error retrieving event decks"));
    }
  };
