import {
  BEGIN_LOAD_DECKS,
  FINISH_LOAD_DECKS,
  ERROR_LOAD_DECKS,
  BEGIN_LOAD_EVENT_DECKS,
  FINISH_LOAD_EVENT_DECKS,
  ERROR_LOAD_EVENT_DECKS,
  BEGIN_LOAD_ARCHETYPE_DECKS,
  FINISH_LOAD_ARCHETYPE_DECKS,
  ERROR_LOAD_ARCHETYPE_DECKS,
  BEGIN_LOAD_DECK,
  FINISH_LOAD_DECK,
  ERROR_LOAD_DECK,
} from "./decksReducer";

export const UPDATE_INCLUDE_LEAGUES = "UPDATE_INCLUDE_LEAGUES";
export const UPDATE_DAYS_AGO = "UPDATE_DAYS_AGO";

export interface DecksFeatureState {
  includeLeagues: boolean;
  daysAgo: number;
  isLoading: boolean;
  error: object | undefined;
}

interface BeginAction {
  type:
    | typeof BEGIN_LOAD_DECKS
    | typeof BEGIN_LOAD_DECK
    | typeof BEGIN_LOAD_EVENT_DECKS
    | typeof BEGIN_LOAD_ARCHETYPE_DECKS;
}

interface ErrorAction {
  type:
    | typeof ERROR_LOAD_DECK
    | typeof ERROR_LOAD_DECKS
    | typeof ERROR_LOAD_EVENT_DECKS
    | typeof ERROR_LOAD_ARCHETYPE_DECKS;
  payload: object;
}

interface FinishAction {
  type:
    | typeof FINISH_LOAD_DECK
    | typeof FINISH_LOAD_DECKS
    | typeof FINISH_LOAD_EVENT_DECKS
    | typeof FINISH_LOAD_ARCHETYPE_DECKS;
}

interface UpdateIncludeLeaguesAction {
  type: typeof UPDATE_INCLUDE_LEAGUES;
  payload: boolean;
}

interface UpdateDaysAgoAction {
  type: typeof UPDATE_DAYS_AGO;
  payload: number;
}

type DeckFeatureAction =
  | BeginAction
  | ErrorAction
  | FinishAction
  | UpdateIncludeLeaguesAction
  | UpdateDaysAgoAction;

export const decksFeatureReducer = (
  state: DecksFeatureState = {
    isLoading: false,
    error: undefined,
    includeLeagues: false,
    daysAgo: 7,
  },
  action: DeckFeatureAction
) => {
  switch (action.type) {
    case BEGIN_LOAD_DECK:
    case BEGIN_LOAD_DECKS:
    case BEGIN_LOAD_EVENT_DECKS:
    case BEGIN_LOAD_ARCHETYPE_DECKS:
      return { ...state, isLoading: true, error: undefined };
    case ERROR_LOAD_DECK:
    case ERROR_LOAD_DECKS:
    case ERROR_LOAD_EVENT_DECKS:
    case ERROR_LOAD_ARCHETYPE_DECKS:
      return { ...state, isLoading: false, error: action.payload };
    case FINISH_LOAD_DECK:
    case FINISH_LOAD_DECKS:
    case FINISH_LOAD_EVENT_DECKS:
    case FINISH_LOAD_ARCHETYPE_DECKS:
      return { ...state, isLoading: false, error: undefined };
    case UPDATE_INCLUDE_LEAGUES:
      return { ...state, includeLeagues: action.payload };
    case UPDATE_DAYS_AGO:
      return { ...state, daysAgo: action.payload };
    default:
      return state;
  }
};

export const updateDaysAgo = (daysAgo: number) => ({
  type: UPDATE_DAYS_AGO,
  payload: daysAgo,
});

export const updateIncludeLeagues = (includeLeagues: boolean) => ({
  type: UPDATE_INCLUDE_LEAGUES,
  payload: includeLeagues,
});
