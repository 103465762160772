import * as React from "react";
import { useSelector } from "react-redux";
import { Row, Col, Alert, Form, Button } from "react-bootstrap";
import gateway, { LeagueDetails } from "./apiGateway";
import Deck from "./Components/Deck";
import { Link, useParams, useHistory } from "react-router-dom";
import Loader from "./Components/Loader";
import {
  Deck as DeckData,
  EmptyDeck,
  getDeckWithCollectionData,
} from "./logic";
import { reducedCollectionSelector } from "./redux/collectionSelectors";
import { useAuth0 } from "@auth0/auth0-react";

const LeageAddDeckPage = () => {
  const { user } = useAuth0();
  const { leagueId } = useParams<{ leagueId: string }>();
  const history = useHistory();
  const [details, setDetails] = React.useState<LeagueDetails>({
    format: "modern",
    name: "",
  });
  const [isLoading, setIsLoading] = React.useState(true);
  const [currentError, setCurrentError] = React.useState<string>();
  const [deckData, setDeckData] = React.useState("");
  const [deckName, setDeckName] = React.useState("");
  const [deck, setDeck] = React.useState<DeckData>(new EmptyDeck());
  const [errorAlertText, setErrorAlertText] = React.useState<string>();
  const reducedCollection = useSelector(reducedCollectionSelector);

  React.useEffect(() => {
    (async () => {
      try {
        const details = await gateway.loadLeagueDetails(leagueId);
        setDetails(details);
        document.title = `MTG Results - ${details.name} - Add Deck`;
      } catch (err) {
        if (err instanceof Error) {
          setCurrentError(err.message);
        }
      } finally {
        setIsLoading(false);
      }
    })();
  }, [leagueId]);

  React.useEffect(() => {
    setDeck({ ...deck, owner: user?.name ?? "" });
  }, [user?.name, deck]);

  const determineSideboardDelimiter = (deckData: string) => {
    if (deckData.includes("///Sideboard")) {
      return "///Sideboard";
    }
    if (deckData.includes("//Sideboard")) {
      return "//Sideboard";
    }

    return "Sideboard";
  };

  const parseDeck = (deckData: string) => {
    const data = deckData
      .split(determineSideboardDelimiter(deckData))
      .map((d) =>
        d
          .split("\n")
          .filter((l) => l.trim() !== "")
          .map((s) => s.trim().split(" "))
          .map((a) => {
            const [count, ...rest] = a;
            return {
              name: rest.join(" "),
              count: parseInt(count),
            };
          })
      );
    const newDeck = {
      ...deck,
      archetype: deckName,
      mainboard: data[0],
      sideboard: data[1] || [],
    };
    setDeck(newDeck);
  };

  const submitDeck = async () => {
    try {
      await gateway.addDeck(leagueId, deck);

      history.push(`/leagues/${leagueId}`);
    } catch (err) {
      if (err instanceof Error) {
        setErrorAlertText(err.message);
      }
    }
  };

  return (
    <React.Fragment>
      {errorAlertText && (
        <Alert
          variant="danger"
          onClose={() => setErrorAlertText(undefined)}
          dismissible
        >
          <Alert.Heading>Could not submit deck</Alert.Heading>
          {errorAlertText}
        </Alert>
      )}
      <Loader isLoading={isLoading} error={currentError || ""}>
        <h1>Add Deck for League</h1>
        <h2>{`${details.name} (${details.format})`}</h2>
        <p>
          <Link to={`/leagues/${leagueId}`}>Return to League</Link>
        </p>
        <Row>
          <Col>
            <Form.Group>
              <Form.Label>Deck Name</Form.Label>
              <Form.Control
                type="text"
                defaultValue={deckName}
                onChange={(e) => {
                  setDeckName(e.target.value);
                }}
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>Decklist</Form.Label>
              <Form.Control
                type="text"
                as="textarea"
                rows={30}
                defaultValue={deckData}
                onChange={(e) => {
                  setDeckData(e.target.value);
                }}
              />
            </Form.Group>
          </Col>
          <Col>
            {details && (
              <React.Fragment>
                {deck && (
                  <Deck
                    deck={getDeckWithCollectionData(deck, reducedCollection)}
                    format={details.format.toLowerCase()}
                    isSignedIn={false}
                    isLoadingCollection={false}
                    showLink={false}
                  />
                )}
              </React.Fragment>
            )}
          </Col>
        </Row>
        <Row>
          <Col>
            <Button variant="secondary" onClick={() => parseDeck(deckData)}>
              Update Preview
            </Button>
          </Col>
          <Col>
            <Button variant="primary" onClick={async () => await submitDeck()}>
              Submit Deck
            </Button>
          </Col>
        </Row>
      </Loader>
    </React.Fragment>
  );
};

export default LeageAddDeckPage;
