import * as React from "react";
import { Row, Col } from "react-bootstrap";
import scryfallGateway, { ScryfallCard } from "../scryfallGateway";

interface CardDetailsProps {
  card: string;
}

const CardDetails = ({ card }: CardDetailsProps) => {
  const [cardData, setCardData] = React.useState<ScryfallCard>({
    name: card,
    image_uris: { normal: "", small: "" },
    card_faces: [],
    prices: { usd: 0 },
  });

  React.useEffect(() => {
    if (card) {
      (async () => {
        const cardData = await scryfallGateway.getCard(card);
        setCardData(cardData);
      })();
    }
  }, [card]);

  const imgUrl =
    cardData.image_uris || false
      ? cardData.image_uris.normal
      : cardData.card_faces[0].image_uris.normal;

  return (
    <React.Fragment>
      <Row>
        <Col>
          <img src={imgUrl} alt={cardData.name} />
        </Col>
      </Row>
      <Row>
        <Col>
          <h3>Price: ${cardData.prices.usd}</h3>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default CardDetails;
