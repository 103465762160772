import * as React from "react";

const Loader = ({
  isLoading,
  error,
  children,
}: {
  isLoading: boolean;
  error: string | undefined;
  children: JSX.Element | JSX.Element[];
}) => {
  return (
    <React.Fragment>
      {isLoading && <h1>Loading...</h1>}
      {!isLoading && error && <h1>{error}</h1>}
      {!isLoading && !error && <React.Fragment>{children}</React.Fragment>}
    </React.Fragment>
  );
};

export default Loader;
