import * as React from "react";
import Deck from "./Deck";
import CollectionButtons from "./CollectionButtons";

import type { DeckWithCollectionData } from "../logic";

interface DeckSmallProps {
  deck: DeckWithCollectionData;
  format: string;
  isSignedIn: boolean;
  isLoadingCollection: boolean;
  selectedCard?: string;
  setSelectedCard: (name: string) => void;
}

const DeckSmall = ({
  deck,
  format,
  isSignedIn,
  isLoadingCollection,
  selectedCard,
  setSelectedCard,
}: DeckSmallProps) => {
  return (
    <React.Fragment>
      <CollectionButtons />
      <Deck
        deck={deck}
        format={format}
        selectedCard={selectedCard}
        selectCard={(c) => setSelectedCard(c)}
        isSignedIn={isSignedIn}
        isLoadingCollection={isLoadingCollection}
      />
    </React.Fragment>
  );
};

export default DeckSmall;
