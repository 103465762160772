import * as React from "react";
import { Link } from "react-router-dom";
import Skeleton from "./Skeleton";

interface EventProps {
  isLoading: boolean;
  format: string;
  events: Array<{ event: string; date: Date }>;
}

const Event = ({ isLoading, format, events }: EventProps) => {
  return (
    <React.Fragment>
      <h2>Events</h2>
      <table className="table">
        <thead>
          <tr>
            <th>Event</th>
            <th>Date</th>
          </tr>
        </thead>
        <tbody>
          {isLoading && (
            <tr>
              <td>
                <Skeleton count={20} />
              </td>
              <td>
                <Skeleton count={20} />
              </td>
            </tr>
          )}
          {!isLoading &&
            events.map((e, index) => (
              <tr key={index}>
                <td>
                  <Link
                    to={`/${format}/event/${encodeURIComponent(
                      e.event
                    )}/${e.date.valueOf()}`}
                  >
                    {e.event}
                  </Link>
                </td>
                <td>{e.date.toLocaleDateString("en-US")}</td>
              </tr>
            ))}
        </tbody>
      </table>
    </React.Fragment>
  );
};

export default Event;
