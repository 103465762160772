import axios from "axios";
import { Collection, Deck } from "./logic";

const apiUrl = process.env["REACT_APP_API_URL"] ?? "";
if (apiUrl === "") {
  throw new Error("Missing REACT_APP_API_URL");
}

axios.interceptors.request.use(
  (config) => {
    if (config === undefined || config.headers === undefined) return;
    const token = localStorage.getItem("token");
    if (token && config.url?.startsWith(apiUrl)) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    config.headers["Content-Type"] = "application/json";

    return config;
  },
  (error: Error) => {
    // Do something with request error
    return Promise.reject(error);
  }
);

export interface LeagueDetails {
  name: string;
  format: string;
}

export interface League {
  _id: string;
  format: string;
  startDate: Date;
  name: string;
}

export interface LeagueMatchResult {
  round: number;
  matchId: string;
  player1Wins: number;
  player2Wins: number;
}

export interface LeagueMatch {
  matchId: string;
  round: number;
  player1GoogleId: string;
  player1Name: string;
  player2GoogleId: string;
  player2Name: string;
}

export interface PlayerDeck extends Deck {
  playerId: string;
  playerObjectId: string;
}

export interface Profile {}

const apiGateway = {
  getDecksContainingCard: async (cardName: string) => {
    const response = await axios.get<Deck[]>(
      `${apiUrl}/cards/${cardName}/decks`
    );

    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error(response.statusText);
    }
  },
  loadPlayerDecks: async (playerName: string) => {
    const response = await axios.get<Deck[]>(
      `${apiUrl}/decks?owner=${playerName}`
    );

    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error(response.statusText);
    }
  },
  loadProfile: async (googleId: string) => {
    const token = localStorage.getItem("token");
    const headers: { Authorization?: string } = {};
    if (token) {
      headers.Authorization = `Bearer ${token}`;
    }
    const response = await axios.get<Profile>(`${apiUrl}/users/${googleId}`, {
      headers: headers,
    });

    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error(response.statusText);
    }
  },
  loadDecksByDate: async (format: string, date: Date) => {
    const response = await axios.get<Deck[]>(
      `${apiUrl}/decks?format=${encodeURIComponent(
        format
      )}&startDate=${date.valueOf()}`
    );

    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error(response.statusText);
    }
  },
  loadArchetypeDecks: async (archetype: string, format: string) => {
    const response = await axios.get<Deck[]>(
      `${apiUrl}/decks?format=${encodeURIComponent(
        format
      )}&archetype=${encodeURIComponent(archetype)}`
    );

    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error(response.statusText);
    }
  },
  loadEventDecks: async (event: string, date: Date, format: string) => {
    const response = await axios.get<Deck[]>(
      `${apiUrl}/decks?format=${encodeURIComponent(
        format
      )}&event=${encodeURIComponent(event)}&date=${date.valueOf()}`
    );

    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error(response.statusText);
    }
  },
  loadLeagueResults: async (leagueId: string) => {
    const response = await axios.get<LeagueMatchResult[]>(
      `${apiUrl}/leagues/${leagueId}/results`
    );

    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error(response.statusText);
    }
  },
  loadMatchResults: async (leagueId: string, matchId: string) => {
    const response = await axios.get<LeagueMatchResult>(
      `${apiUrl}/leagues/${leagueId}/matches/${matchId}/results`
    );

    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error(response.statusText);
    }
  },
  loadLeagueMatch: async (leagueId: string, matchId: string) => {
    const response = await axios.get<LeagueMatch>(
      `${apiUrl}/leagues/${leagueId}/matches/${matchId}`
    );

    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error(response.statusText);
    }
  },
  saveMatchResults: async (
    leagueId: string,
    matchId: string,
    matchResults: {}
  ) => {
    const response = await axios.post<{ body: string }>(
      `${apiUrl}/leagues/${leagueId}/matches/${matchId}/results`,
      {
        body: JSON.stringify(matchResults),
      }
    );
    if (response.status !== 201) {
      throw new Error(response.data.body);
    }
  },
  loadLeagueMatches: async (leagueId: string) => {
    const response = await axios.get<LeagueMatch[]>(
      `${apiUrl}/leagues/${leagueId}/matches`
    );

    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error(response.statusText);
    }
  },
  createMatch: async (leagueId: string, match: {}) => {
    const response = await axios.post<{ body: string }>(
      `${apiUrl}/leagues/${leagueId}/matches`,
      {
        body: JSON.stringify(match),
      }
    );
    if (response.status !== 201) {
      throw new Error(response.data.body);
    }
  },
  loadDecks: async (format: string) => {
    const response = await axios.get<Deck[]>(
      `${apiUrl}/decks?format=${format}`
    );
    return response.data;
  },
  deleteDeck: async (id: string) => {
    await axios.delete(`${apiUrl}/decks/${id}`);
  },
  updateDeck: async (id: string, updates: object) => {
    await axios.patch(`${apiUrl}/decks/${id}`, updates);
  },
  loadDeck: async (id: string) => {
    const response = await axios.get<Deck>(`${apiUrl}/decks/${id}`);
    return response.data;
  },
  loadCollection: async (userId: string) => {
    const response = await axios.get<Collection[]>(
      `${apiUrl}/users/${userId}/collections`
    );
    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error(response.statusText);
    }
  },
  loadLeagues: async () => {
    const response = await axios.get<League[]>(`${apiUrl}/leagues`);

    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error(response.statusText);
    }
  },
  loadLeagueDetails: async (leagueId: string) => {
    const response = await axios.get<LeagueDetails>(
      `${apiUrl}/leagues/${leagueId}`
    );
    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error(response.statusText);
    }
  },
  addDeck: async (leagueId: string, deck: {}) => {
    const response = await axios.post<{ body: string }>(
      `${apiUrl}/leagues/${leagueId}/players`,
      {
        body: JSON.stringify(deck),
      }
    );
    if (response.status !== 201) {
      throw new Error(response.data.body);
    }
  },
  loadLeagueDecks: async (leagueId: string) => {
    const response = await axios.get<PlayerDeck[]>(
      `${apiUrl}/leagues/${leagueId}/players`
    );

    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error(response.statusText);
    }
  },
};

export default apiGateway;
