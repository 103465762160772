import { createSelector } from "reselect";
import { decksIOwnFeatureSelector } from "./reducer";

export const selectedFormatSelector = createSelector(
  decksIOwnFeatureSelector,
  (collectionFeature) => collectionFeature.selectedFormat
);

export const includeLeaguesSelector = createSelector(
  decksIOwnFeatureSelector,
  (collectionFeature) => collectionFeature.includeLeagues
);

export const maximumMissingCardsSelector = createSelector(
  decksIOwnFeatureSelector,
  (collectionFeature) => collectionFeature.maximumMissingCards
);
