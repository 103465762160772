import { Container, Navbar, Nav, NavDropdown } from "react-bootstrap";
import { Switch, Route, Redirect } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";

import Formats from "./Formats";
import EventPage from "./EventPage";
import ArchetypePage from "./ArchetypePage";
import FormatPage from "./FormatPage";
import DeckPage from "./DeckPage";
import NewDeckPage from "./NewDeckPage";
import CollectionPage from "./CollectionPage";
import LeaguePage from "./LeaguePage";
import LeagueDetailPage from "./LeagueDetailPage";
import LeagueAddDeckPage from "./LeagueAddDeckPage";
import LeagueAddMatchPage from "./LeagueAddMatchPage";
import LeagueMatchesPage from "./LeagueMatchesPage";
import PlayerPage from "./PlayerPage";
import CardPage from "./CardPage";
import UpdatePaperPage from "./UpdatePaperPage";
import DecksIOwnPage from "./DecksIOwnPage";
import AddNewLeaguePage from "./AddNewLeaguePage";
import LeagueAddMatchResultsPage from "./LeagueAddMatchResultsPage";
import SignIn from "./Components/SignIn";
import "./App.css";
import ErrorBoundary from "./Components/ErrorBoundary";
import { BrowserRouter as Router } from "react-router-dom";
import "json.date-extensions";
import PrivateRoute from "./Components/PrivateRoute";

declare global {
  interface JSON {
    useDateParser(): () => void;
  }
}

const App = () => {
  JSON.useDateParser();

  return (
    <Router>
      <Container>
        <Navbar bg="light" expand="lg">
          <LinkContainer to="/">
            <Navbar.Brand>MTG Results</Navbar.Brand>
          </LinkContainer>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto">
              <NavDropdown title="Results" id="basic-nav-dropdown">
                <LinkContainer to="/pauper">
                  <NavDropdown.Item>Pauper</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to="/legacy">
                  <NavDropdown.Item href="#action/3.2">Legacy</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to="/modern">
                  <NavDropdown.Item>Modern</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to="/pioneer">
                  <NavDropdown.Item>Pioneer</NavDropdown.Item>
                </LinkContainer>
              </NavDropdown>
              <LinkContainer to="/collection">
                <Nav.Link>Collection</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/updatepaper">
                <Nav.Link>Upload</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/decksiown">
                <Nav.Link>Decks I Own</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/newdeck">
                <Nav.Link>New Deck</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/leagues">
                <Nav.Link>Leagues</Nav.Link>
              </LinkContainer>
            </Nav>
            <Navbar.Collapse className="justify-content-end">
              <SignIn />
            </Navbar.Collapse>
          </Navbar.Collapse>
        </Navbar>
        <ErrorBoundary>
          <Switch>
            <Route path="/:format(modern|legacy|pauper|pioneer)/deck/:id">
              <DeckPage />
            </Route>
            <Route path="/:format(modern|legacy|pauper|pioneer)/event/:event/:date">
              <EventPage />
            </Route>
            <Route path="/:format(modern|legacy|pauper|pioneer)/archetype/:archetype">
              <Formats>
                <ArchetypePage />
              </Formats>
            </Route>
            <Route path="/:format(modern|legacy|pauper|pioneer)">
              <Formats>
                <FormatPage />
              </Formats>
            </Route>
            <Route exact path="/player/:name">
              <PlayerPage />
            </Route>
            <Route exact path="/card/:name">
              <CardPage />
            </Route>
            <PrivateRoute
              message="You must be signed in to check a new deck against your collection."
              exact
              path="/newdeck"
            >
              <NewDeckPage />
            </PrivateRoute>
            <PrivateRoute
              message="Please sign in to view collection"
              exact
              path="/collection"
            >
              <CollectionPage />
            </PrivateRoute>
            <PrivateRoute
              message="Please sign in to view leagues"
              exact
              path="/leagues"
            >
              <LeaguePage />
            </PrivateRoute>
            <PrivateRoute
              message="You must be signed in to add a new league."
              exact
              path="/leagues/new"
            >
              <AddNewLeaguePage />
            </PrivateRoute>
            <PrivateRoute
              message="You must be signed in to add your deck."
              exact
              path="/leagues/:leagueId/addMyDeck"
            >
              <LeagueAddDeckPage />
            </PrivateRoute>
            <PrivateRoute
              message="You must be signed in to view matches"
              exact
              path="/leagues/:leagueId/matches"
            >
              <LeagueMatchesPage />
            </PrivateRoute>
            <PrivateRoute
              message="You must be signed in to add a match."
              exact
              path="/leagues/:leagueId/addMatch"
            >
              <LeagueAddMatchPage />
            </PrivateRoute>
            <PrivateRoute
              message="You must be signed in to add match results."
              exact
              path="/leagues/:leagueId/matches/:matchId/addResults"
            >
              <LeagueAddMatchResultsPage />
            </PrivateRoute>
            <PrivateRoute
              message="Please sign in to view the league."
              exact
              path="/leagues/:leagueId"
            >
              <LeagueDetailPage />
            </PrivateRoute>
            <PrivateRoute
              message="You must be signed in to update your paper collection."
              exact
              path="/updatepaper"
            >
              <UpdatePaperPage />
            </PrivateRoute>
            <PrivateRoute
              message="You must be signed in to view decks you own."
              exact
              path="/decksiown"
            >
              <DecksIOwnPage />
            </PrivateRoute>
            <Route exact path="/">
              <Redirect to="/modern" />
            </Route>
            <Route path="*">
              <h1>Not Found</h1>
            </Route>
          </Switch>
        </ErrorBoundary>
      </Container>
    </Router>
  );
};

export default App;
