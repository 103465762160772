import { Dispatch } from "redux";
import apiGateway from "../apiGateway";
import { Collection } from "../logic";

export const FINISH_LOAD_COLLECTION = "FINISH_LOAD_COLLECTION";
export const ERROR_LOAD_COLLECTION = "ERROR_LOAD_COLLECTION";
export const BEGIN_LOAD_COLLECTION = "BEGIN_LOAD_COLLECTION";
export const CLEAR_COLLECTION = "CLEAR_COLLECTION";

interface ClearCollectionAction {
  type: typeof CLEAR_COLLECTION;
}

interface FinishLoadCollectionAction {
  type: typeof FINISH_LOAD_COLLECTION;
  payload: Collection[];
}

type CollectionAction = ClearCollectionAction | FinishLoadCollectionAction;

export const collectionReducer = (state = [], action: CollectionAction) => {
  switch (action.type) {
    case CLEAR_COLLECTION:
      return [];
    case FINISH_LOAD_COLLECTION:
      return action.payload;
    default:
      return state;
  }
};

export const clearCollection = () => ({
  type: CLEAR_COLLECTION,
});

const beginLoadCollection = () => ({ type: BEGIN_LOAD_COLLECTION });
const finishLoadCollection = (collection: Collection[]) => ({
  type: FINISH_LOAD_COLLECTION,
  payload: collection,
});
const errorLoadCollection = (error: string) => ({
  type: ERROR_LOAD_COLLECTION,
  payload: error,
});

export const loadCollection =
  (userId: string) => async (dispatch: Dispatch) => {
    dispatch(beginLoadCollection());
    try {
      const collection = await apiGateway.loadCollection(userId);
      dispatch(finishLoadCollection(collection));
    } catch (error) {
      console.error(error);
      dispatch(errorLoadCollection("Error retrieving collection"));
    }
  };
