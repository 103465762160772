export interface DecksIOwnFeatureState {
  selectedFormat: string;
  includeLeagues: boolean;
  maximumMissingCards: number;
}

interface UpdateSelectedFormat {
  type: string;
  payload: string;
}

interface UpdateIncludeLeagues {
  type: string;
  payload: boolean;
}

interface UpdateMaximumMissingCards {
  type: string;
  payload: 0 | 1 | 2 | 3 | 4 | 5;
}

type UpdateFiltersAction =
  | UpdateSelectedFormat
  | UpdateIncludeLeagues
  | UpdateMaximumMissingCards;

export const UPDATE_SELECTED_FORMAT = "UPDATE_SELECTED_FORMAT";
export const UPDATE_INCLUDE_LEAGUES = "UPDATE_INCLUDE_LEAGUES";
export const UPDATE_MAXIMUM_MISSING_CARDS = "UPDATE_MAXIMUM_MISSING_CARDS";

export const updateSelectedFormat = (selectedFormat: string) => ({
  type: UPDATE_SELECTED_FORMAT,
  payload: selectedFormat,
});

export const updateIncludeLeagues = (includeLeagues: boolean) => ({
  type: UPDATE_INCLUDE_LEAGUES,
  payload: includeLeagues,
});

export const updateMaximumMissingCards = (maximumMissingCards: number) => ({
  type: UPDATE_MAXIMUM_MISSING_CARDS,
  payload: maximumMissingCards,
});

export const decksIOwnFeatureReducer = (
  state: DecksIOwnFeatureState = {
    includeLeagues: false,
    selectedFormat: "Modern",
    maximumMissingCards: 0,
  },
  action: UpdateFiltersAction
) => {
  switch (action.type) {
    case UPDATE_SELECTED_FORMAT:
      return { ...state, selectedFormat: action.payload };
    case UPDATE_INCLUDE_LEAGUES:
      return { ...state, includeLeagues: action.payload };
    case UPDATE_MAXIMUM_MISSING_CARDS:
      return { ...state, maximumMissingCards: action.payload };
    default:
      return state;
  }
};
