export interface DeckCards<T extends DeckCard> {
  mainboard: Array<T>;
  sideboard: Array<T>;
}

export interface DeckCard {
  name: string;
  count: number;
}

export interface DeckCardWithCollectionData extends DeckCard {
  name: string;
  count: number;
  amountInCollection: number;
}

export interface Collection {
  name: string;
  cards: Array<CollectionCard>;
}

export interface Deck extends DeckCards<DeckCard> {
  _id: string;
  name: string;
  archetype: string;
  owner: string;
  event: string;
  date: Date;
  format: string;
  place: string;
}

export class EmptyDeck implements Deck {
  _id: string = "";
  name: string = "";
  archetype: string = "";
  owner: string = "";
  event: string = "";
  date: Date = new Date();
  format: string = "";
  place: string = "";
  mainboard: { name: string; count: number }[] = [];
  sideboard: { name: string; count: number }[] = [];
}

export interface DeckWithCollectionData extends Deck {
  mainboard: Array<{
    name: string;
    count: number;
    totalAmountInDeck: number;
    amountInCollection: number;
  }>;
  sideboard: Array<{
    name: string;
    count: number;
    totalAmountInDeck: number;
    amountInCollection: number;
  }>;
  totalCardsNeeded: number;
}

export class EmptyDeckWithCollectionData implements DeckWithCollectionData {
  mainboard: {
    name: string;
    count: number;
    totalAmountInDeck: number;
    amountInCollection: number;
  }[] = new Array<{
    name: string;
    count: number;
    totalAmountInDeck: number;
    amountInCollection: number;
  }>();
  sideboard: {
    name: string;
    count: number;
    totalAmountInDeck: number;
    amountInCollection: number;
  }[] = new Array<{
    name: string;
    count: number;
    totalAmountInDeck: number;
    amountInCollection: number;
  }>();
  totalCardsNeeded: number = 0;
  _id: string = "";
  name: string = "";
  archetype: string = "";
  owner: string = "";
  event: string = "";
  date: Date = new Date();
  format: string = "";
  place: string = "";
}

export interface CollectionCard {
  set_code: string;
  card_name: string;
  amount: number;
}

export interface MetagameSummaryItem {
  archetype: string;
  count: number;
  percentage: number;
}

export const getNumberInCollection = (
  reducedCollection: Array<CollectionCard>,
  cardName: string
) => {
  const cards = reducedCollection.filter((c) =>
    matchNames(c.card_name, cardName)
  );

  if (cards.length > 0) return cards[0].amount;
  else return 0;
};

export const summarizeMetagame = (decks: Array<{ archetype: string }>) => {
  return decks.reduce((r, v) => {
    const archetype = r.filter((a) => a.archetype === v.archetype)[0];
    if (archetype) {
      archetype.count += 1;
      archetype.percentage = archetype.count / decks.length;
    } else {
      r.push({
        archetype: v.archetype,
        count: 1,
        percentage: 1 / decks.length,
      });
    }
    return r;
  }, new Array<MetagameSummaryItem>());
};

export const summarizeMostPlayedCards = (decks: Array<Deck>) => {
  const combinedDecks = combineDecks(decks.map(summarizeDeck))
    .filter(
      (c) =>
        !(
          c.name === "Swamp" ||
          c.name === "Snow-Covered Swamp" ||
          c.name === "Mountain" ||
          c.name === "Snow-Covered Mountain" ||
          c.name === "Plains" ||
          c.name === "Snow-Covered Plains" ||
          c.name === "Island" ||
          c.name === "Snow-Covered Island" ||
          c.name === "Forest" ||
          c.name === "Snow-Covered Forest"
        )
    )
    .sort((a, b) => (a.qty < b.qty ? 1 : -1))
    .slice(0, 10);

  return combinedDecks;
};

export const combineDecks = (
  decks: Array<Array<{ name: string; count: number }>>
) => {
  var cards = new Array<{
    name: string;
    qty: number;
    mincount: number;
    maxcount: number;
    numberOfDecks: number;
    percentageOfDecks: number;
  }>();

  for (const deck of decks) {
    for (const card of deck) {
      const foundCard = cards.find((c) => c.name === card.name);
      if (foundCard) {
        foundCard.qty += card.count;
        foundCard.mincount =
          card.count < foundCard.mincount ? card.count : foundCard.mincount;
        foundCard.maxcount =
          card.count > foundCard.maxcount ? card.count : foundCard.maxcount;
        foundCard.numberOfDecks += 1;
        foundCard.percentageOfDecks = foundCard.numberOfDecks / decks.length;
      } else {
        cards.push({
          name: card.name,
          qty: card.count,
          mincount: card.count,
          maxcount: card.count,
          numberOfDecks: 1,
          percentageOfDecks: 1 / decks.length,
        });
      }
    }
  }

  return cards;
};

export const summarizeDeck = <T extends DeckCard>(deck: DeckCards<T>) => {
  const cards = deck.mainboard.map((c) => ({ ...c }));

  for (const card of deck.sideboard) {
    const foundCard = cards.find((c) => c.name === card.name);
    if (foundCard) {
      foundCard.count = foundCard.count + card.count;
    } else {
      cards.push({ ...card, count: card.count });
    }
  }
  return cards;
};

export const stripName = (name: string) => {
  if (name.includes("//")) {
    return name.split("//").map((s) => s.trim());
  }
  if (name.includes("/")) {
    return name.split("/").map((s) => s.trim());
  }

  return [name];
};

export const matchNames = (name1: string, name2: string) => {
  const names = stripName(name1).map((r) => r.toLowerCase());
  const otherNames = stripName(name2).map((r) => r.toLowerCase());
  for (const name of names) {
    if (otherNames.includes(name)) {
      return true;
    }
  }
  return false;
};

//TODO: clean up
export const getDeckWithCollectionData = (
  deck: Deck,
  reducedCollection: Array<CollectionCard>
) => {
  const summarizedDeck = summarizeDeck(deck);
  const newDeck: DeckWithCollectionData = {
    ...deck,
    mainboard: deck.mainboard.map((m) => ({
      ...m,
      totalAmountInDeck: 0,
      amountInCollection: 0,
    })),
    sideboard: deck.sideboard.map((m) => ({
      ...m,
      totalAmountInDeck: 0,
      amountInCollection: 0,
    })),
    totalCardsNeeded: 0,
  };
  let totalCardsNeeded = 0;

  let normalizedReducedCollection = new Map(
    reducedCollection.map((rc) => [
      stripName(rc.card_name)[0].toLowerCase().trim(),
      rc,
    ])
  );

  for (const card of newDeck.mainboard) {
    const cardName = stripName(card.name)[0];
    const collectionCard = normalizedReducedCollection.get(
      cardName.toLowerCase().trim()
    );
    if (!collectionCard) {
      card.amountInCollection = 0;
    } else {
      card.amountInCollection = collectionCard.amount;
    }
    const summarizedCard = summarizedDeck.find(
      (d) => d.name === card.name || matchNames(d.name, card.name)
    ) || { count: 0 };
    card.totalAmountInDeck = summarizedCard.count;
    totalCardsNeeded += Math.max(card.count - card.amountInCollection, 0);
  }

  for (const card of newDeck.sideboard) {
    const cardName = stripName(card.name)[0];
    const collectionCard = normalizedReducedCollection.get(
      cardName.toLowerCase().trim()
    );
    if (collectionCard) {
      card.amountInCollection = collectionCard.amount;
    } else {
      card.amountInCollection = 0;
    }
    const summarizedCard = summarizedDeck.find(
      (d) => d.name === card.name || matchNames(d.name, card.name)
    ) || { count: 0 };
    card.totalAmountInDeck = summarizedCard.count;
    totalCardsNeeded += Math.max(
      summarizedCard.count - card.amountInCollection,
      0
    );
  }

  newDeck.totalCardsNeeded = totalCardsNeeded;
  return newDeck;
};

export const reduceCollection = (collection: Array<CollectionCard>) => {
  return collection.reduce((a, b) => {
    var card = a.find((c) => matchNames(c.card_name, b.card_name));
    if (card) {
      const index = a.indexOf(card);
      const newArray = [...a];
      newArray[index] = { ...card, amount: b.amount + card.amount };
      return newArray;
    } else {
      return [
        ...a,
        { card_name: b.card_name, amount: b.amount, set_code: b.set_code },
      ];
    }
  }, new Array<CollectionCard>());
};

export const getDaysAgo = (numberOfDays: number) => {
  var date1 = new Date();
  date1.setDate(date1.getDate() - numberOfDays);

  return new Date(date1.getFullYear(), date1.getMonth(), date1.getDate());
};
