import * as React from "react";
import { useSelector } from "react-redux";
import { Row, Col } from "react-bootstrap";

import Deck from "./Components/Deck";
import CardsNeeded from "./Components/CardsNeeded";
import CollectionButtons from "./Components/CollectionButtons";
import { getDeckWithCollectionData } from "./logic";
import { reducedCollectionSelector } from "./redux/collectionSelectors";
import type { DeckWithCollectionData } from "./logic";

const NewDeckPage = () => {
  const reducedCollection = useSelector(reducedCollectionSelector);
  const [deck, setDeck] = React.useState<DeckWithCollectionData>({
    _id: "a",
    event: "test",
    date: new Date(),
    owner: "test",
    name: "test",
    archetype: "test",
    format: "test",
    place: "test",
    mainboard: [],
    sideboard: [],
    totalCardsNeeded: 0,
  });
  const [deckData, setDeckData] = React.useState<string>();

  const parseDeck = (deckData: string | undefined) => {
    if (!deckData) return;
    const data = deckData.split(`///Sideboard`).map((d) =>
      d
        .split("\n")
        .filter((l) => l.trim() !== "")
        .map((s) => s.trim().split(" "))
        .map((a) => {
          const [count, ...rest] = a;
          return {
            name: rest.join(" "),
            count: parseInt(count),
          };
        })
    );
    const newDeck = {
      ...deck,
      _id: "a",
      mainboard: data[0],
      sideboard: data[1] || [],
    };
    setDeck(getDeckWithCollectionData(newDeck, reducedCollection));
  };

  React.useEffect(() => {
    document.title = `MTG Results - New Deck`;
  }, []);

  return (
    <Row>
      <Col>
        <textarea
          rows={50}
          defaultValue={deckData}
          onChange={(e) => setDeckData(e.target.value)}
        />
        <button onClick={() => parseDeck(deckData)}>Check Deck</button>
      </Col>
      <Col>
        {deck && (
          <React.Fragment>
            <CollectionButtons />
            <Deck
              deck={deck}
              format="modern"
              isSignedIn={true}
              isLoadingCollection={false}
              showLink={false}
            />
          </React.Fragment>
        )}
      </Col>
      <Col>{deck && <CardsNeeded isLoading={false} deck={deck} />}</Col>
    </Row>
  );
};

export default NewDeckPage;
