import axios from "axios";

export interface ScryfallCard {
  name: string;
  image_uris: { normal: string; small: string };
  card_faces: Array<{ image_uris: { normal: string; small: string } }>;
  prices: { usd: number };
}

const scryfallGateway = {
  getCard: async (card_name: string) => {
    const response = await axios.get<ScryfallCard>(
      `https://api.scryfall.com/cards/named?exact=${card_name}`
    );
    return response.data;
  },
};

export default scryfallGateway;
