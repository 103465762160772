import * as React from "react";
import { OverlayTrigger } from "react-bootstrap";
import { CardDisplay } from "./CardDisplay";
import Skeleton from "./Skeleton";

interface DeckSectionProps {
  isLoading?: boolean;
  title: string;
  data: Array<{
    name: string;
    count: number;
    amountInCollection: number;
    totalAmountInDeck: number;
  }>;
  selectCard: (name: string) => void;
  isSignedIn: boolean;
  isLoadingCollection: boolean;
  selectedCard?: string;
  propToUseForTotalCount?: "count" | "totalAmountInDeck";
}

const DeckSection = ({
  isLoading,
  title,
  data,
  selectCard,
  isSignedIn,
  isLoadingCollection,
  selectedCard,
  propToUseForTotalCount = "count",
}: DeckSectionProps) => {
  const getCountOfCards = (data: Array<{ count: number }>) => {
    return data.map((c) => Number(c.count)).reduce((a, b) => a + b, 0);
  };

  const isDisplayingCollection = isSignedIn && !isLoadingCollection;

  return (
    <React.Fragment>
      <h3>
        {title}
        {!isLoading && `(${getCountOfCards(data)})`}
      </h3>
      <table>
        <tbody>
          {isLoading && (
            <tr>
              <td width="100">
                <Skeleton count={20} />
              </td>
              <td width="500">
                <Skeleton count={20} />
              </td>
            </tr>
          )}
          {!isLoading &&
            data &&
            data.map((c, index) => (
              <tr
                key={index}
                className={
                  isDisplayingCollection && c.amountInCollection === 0
                    ? "missing"
                    : isDisplayingCollection &&
                      c.amountInCollection < c[propToUseForTotalCount]
                    ? "partial"
                    : ""
                }
                onMouseOver={() => selectCard(c.name)}
              >
                <td>
                  {c.count}

                  {isDisplayingCollection &&
                    c.amountInCollection < c[propToUseForTotalCount] && (
                      <span>
                        (-
                        {Math.min(
                          c[propToUseForTotalCount] - c.amountInCollection,
                          c.count
                        )}
                        )
                      </span>
                    )}
                </td>
                <td>
                  {selectedCard === c.name ? (
                    <OverlayTrigger
                      trigger={["hover", "focus"]}
                      key={`popover${index}`}
                      placement="right"
                      overlay={
                        <div>
                          <CardDisplay selectedCard={c.name ?? ""} />
                        </div>
                      }
                    >
                      <span>{c.name}</span>
                    </OverlayTrigger>
                  ) : (
                    <span>{c.name}</span>
                  )}
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </React.Fragment>
  );
};

export default DeckSection;
