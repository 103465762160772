import * as React from "react";
import { useParams } from "react-router-dom";
import gateway, { PlayerDeck } from "./apiGateway";
import { Link } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import Deck from "./Components/Deck";
import Loader from "./Components/Loader";
import { useSelector } from "react-redux";
import { Deck as DeckData, getDeckWithCollectionData } from "./logic";
import { reducedCollectionSelector } from "./redux/collectionSelectors";
import { isLoadingCollectionSelector } from "./redux/collectionFeatureSelectors";
import { useAuth0 } from "@auth0/auth0-react";

const LeagueDetailPage = () => {
  const { leagueId } = useParams<{ leagueId: string }>();
  const [details, setDetails] =
    React.useState<{ name: string; format: string }>();
  const [decks, setDecks] = React.useState<Array<DeckData>>([]);
  const [hasMyDeck, setHasMyDeck] = React.useState(false);
  const [currentError, setCurrentError] = React.useState<string>();
  const [isLoading, setIsLoading] = React.useState(true);
  const reducedCollection = useSelector(reducedCollectionSelector);
  const { isAuthenticated, user } = useAuth0();
  const isLoadingCollection = useSelector(isLoadingCollectionSelector);

  React.useEffect(() => {
    (async () => {
      try {
        const details = await gateway.loadLeagueDetails(leagueId);
        setDetails(details);

        const decksTemp: Array<PlayerDeck> = await gateway.loadLeagueDecks(
          leagueId
        );
        setDecks(decksTemp);
        setHasMyDeck(
          decksTemp.filter((d) => d.playerId === user?.sub).length > 0
        );
      } catch (err) {
        if (err instanceof Error) {
          setCurrentError(err.message);
        }
      } finally {
        setIsLoading(false);
      }
    })();
  }, [leagueId, user?.sub]);

  React.useEffect(() => {
    document.title = `MTG Results - ${details?.name}`;
  }, [details]);

  return (
    <React.Fragment>
      <Loader isLoading={isLoading} error={currentError}>
        <h1>
          {details?.name} ({details?.format})
        </h1>
        {hasMyDeck ? (
          <div />
        ) : (
          <p>
            <Link to={`/leagues/${leagueId}/addMyDeck`}>Add My Deck</Link>
          </p>
        )}
        <p>
          <Link to={`/leagues/${leagueId}/matches`}>View Matches</Link>
        </p>
        <Row>
          {decks.map((d) => (
            <Col key={d.owner}>
              <Deck
                deck={getDeckWithCollectionData(d, reducedCollection)}
                format={details?.format?.toLowerCase() || ""}
                showLink={false}
                isSignedIn={isAuthenticated}
                isLoadingCollection={isLoadingCollection}
              />
            </Col>
          ))}
        </Row>
      </Loader>
    </React.Fragment>
  );
};

export default LeagueDetailPage;
