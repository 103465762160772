import * as React from "react";
import { useParams } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";

import apiGateway from "./apiGateway";
import Pinnable from "./Components/Pinnable";
import Decks from "./Components/Decks";
import DeckSmall from "./Components/DeckSmall";
import { deckSelector2 } from "./redux/decksSelectors";
import { Deck } from "./logic";
import { X } from "react-bootstrap-icons";
import { isLoadingCollectionSelector } from "./redux/collectionFeatureSelectors";
import { GlobalState } from "./redux/reducer";

const PlayerPage = () => {
  const { name } = useParams<{ name: string }>();
  const [playerDecks, setPlayerDecks] = React.useState(new Array<Deck>());
  const [isLoading, setIsLoading] = React.useState(false);
  const [selectedDeck, setSelectedDeck] = React.useState<Deck>();
  const [selectedCard, setSelectedCard] = React.useState<string>();
  const { isAuthenticated } = useAuth0();
  const myDeck = useSelector((state: GlobalState) =>
    deckSelector2(state, selectedDeck)
  );
  const isLoadingCollection = useSelector(isLoadingCollectionSelector);

  React.useEffect(() => {
    if (name) {
      document.title = `MTG Results - ${name}`;
    }
    (async () => {
      setIsLoading(true);
      const decks = await apiGateway.loadPlayerDecks(name);
      setPlayerDecks(decks);
      setIsLoading(false);
    })();
  }, [name]);

  return (
    <React.Fragment>
      <Row>
        <Col>
          <h2>Decks from {name}</h2>
        </Col>
      </Row>
      <Row>
        <Col md={selectedDeck ? 9 : 12}>
          <Decks
            showFormat={true}
            isLoading={isLoading}
            type="big"
            decks={playerDecks}
            onDeckSelected={(deck) => {
              setSelectedDeck(deck);
            }}
            selectedDeck={selectedDeck}
          />
        </Col>
        {selectedDeck && (
          <Col md={3}>
            <Pinnable>
              <div>
                <X
                  style={{ cursor: "pointer" }}
                  onClick={() => setSelectedDeck(undefined)}
                />
              </div>
              <DeckSmall
                deck={myDeck}
                format={selectedDeck?.format || ""}
                selectedCard={selectedCard}
                setSelectedCard={setSelectedCard}
                isSignedIn={isAuthenticated}
                isLoadingCollection={isLoadingCollection}
              />
            </Pinnable>
          </Col>
        )}
      </Row>
    </React.Fragment>
  );
};

export default PlayerPage;
