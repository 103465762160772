import * as React from "react";
import { ButtonGroup, Button } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";

import { updateSelectedCollection } from "../redux/collectionFeatureReducer";
import { selectedCollectionNameSelector } from "../redux/collectionFeatureSelectors";

const CollectionButtons = () => {
  const dispatch = useDispatch();
  const changeCollection = (item1: string) => {
    dispatch(updateSelectedCollection(item1));
  };
  const selectedCollectionName = useSelector(selectedCollectionNameSelector);
  const { isAuthenticated } = useAuth0();

  return (
    <React.Fragment>
      {isAuthenticated && (
        <ButtonGroup aria-label="Collection">
          <Button
            variant="secondary"
            active={"Paper" === selectedCollectionName}
            onClick={() => changeCollection("Paper")}
          >
            Paper
          </Button>
        </ButtonGroup>
      )}
    </React.Fragment>
  );
};

export default CollectionButtons;
