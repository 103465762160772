import {
  BEGIN_LOAD_COLLECTION,
  FINISH_LOAD_COLLECTION,
  ERROR_LOAD_COLLECTION,
} from "./collectionReducer";

export interface CollectionFeatureState {
  isLoading: boolean;
  selectedCollectionName: string;
  error: object | undefined;
}

interface NoPayloadAction {
  type: typeof BEGIN_LOAD_COLLECTION | typeof FINISH_LOAD_COLLECTION;
}

interface ErrorAction {
  type: typeof ERROR_LOAD_COLLECTION;
  payload: string;
}

interface UpdateSelectedCollectionAction {
  type: typeof UPDATE_SELECTED_COLLECTION;
  payload: string;
}

type CollectionFeatureAction =
  | NoPayloadAction
  | ErrorAction
  | UpdateSelectedCollectionAction;

export const collectionFeatureReducer = (
  state: CollectionFeatureState = {
    isLoading: false,
    selectedCollectionName: "Paper",
    error: undefined,
  },
  action: CollectionFeatureAction
) => {
  switch (action.type) {
    case BEGIN_LOAD_COLLECTION:
      return { ...state, isLoading: true, error: undefined };
    case FINISH_LOAD_COLLECTION:
      return { ...state, isLoading: false, error: undefined };
    case ERROR_LOAD_COLLECTION:
      return { ...state, isLoading: false, error: action.payload };
    case UPDATE_SELECTED_COLLECTION:
      return { ...state, selectedCollectionName: action.payload };
    default:
      return state;
  }
};

export const UPDATE_SELECTED_COLLECTION = "UPDATE_SELECTED_COLLECTION";

export const updateSelectedCollection = (selectedCollectionName: string) => ({
  type: UPDATE_SELECTED_COLLECTION,
  payload: selectedCollectionName,
});
