import * as React from "react";
import { Form, Button } from "react-bootstrap";
import { useFormik } from "formik";
import * as yup from "yup";

// yup.addMethod(yup.array, "unique", function (message, mapper = (a: any) => a) {
//   return this.test("unique", message, function (list: Array<Object>) {
//     return list.length === new Set(list.map(mapper)).size;
//   });
// });

const validationSchema = yup.object({
  email: yup
    .array(
      yup.string().email("Enter a valid email").required("Email is required")
    )
    .min(2),
  // .unique(["email must be unique"]),
  name: yup
    .string()
    .min(8, "Name should be of minimum 8 characters length")
    .required("Name is required"),
  number: yup
    .number()
    .min(10)
    .max(20)
    .integer("must be an integer")
    .required("must supply a number"),
  date: yup.date().required("must supply a date"),
});

const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));

const AddNewLeaguePage = () => {
  const {
    handleSubmit,
    values,
    handleChange,
    touched,
    errors,
    isSubmitting,
    setValues,
  } = useFormik({
    initialValues: {
      email: ["foobar@example.com", "t2@example.com"],
      format: "",
      name: "foobar",
      number: 100,
      date: new Date(),
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      await sleep(500);
      alert(JSON.stringify(validationSchema.cast(values), null, 2));
    },
  });
  return (
    <React.Fragment>
      <h1>Add New League</h1>

      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="date">
          <Form.Label>Date</Form.Label>
          <Form.Control
            value={values.date.toString()}
            onChange={handleChange}
            isInvalid={touched.date && Boolean(errors.date)}
            name="date"
            type="date"
          />
          <Form.Control.Feedback type="invalid">
            {touched.date && errors.date}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group controlId="number">
          <Form.Label>Number</Form.Label>
          <Form.Control
            value={values.number}
            onChange={handleChange}
            isInvalid={touched.number && Boolean(errors.number)}
            name="number"
            type="number"
          />
          <Form.Control.Feedback type="invalid">
            {touched.number && errors.number}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group controlId="name">
          <Form.Label>Name</Form.Label>
          <Form.Control
            value={values.name}
            onChange={handleChange}
            isInvalid={touched.name && Boolean(errors.name)}
            name="name"
            type="text"
          />
          <Form.Control.Feedback type="invalid">
            {touched.name && errors.name}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group controlId="format">
          <Form.Label>Format</Form.Label>
          <Form.Control
            value={values.format}
            onChange={handleChange}
            isInvalid={touched.format && Boolean(errors.format)}
            name="format"
            type="text"
          />
          <Form.Control.Feedback type="invalid">
            {touched.format && errors.format}
          </Form.Control.Feedback>
        </Form.Group>
        {values.email.map((_, i) => (
          <Form.Group key={`email[${i}]`} controlId={`email[${i}]`}>
            <Form.Label>{`Player ${i + 1} Email`}</Form.Label>
            <Form.Control
              value={values.email[i]}
              onChange={handleChange}
              // isInvalid={touched.email?.[i] && Boolean(errors.email?.[i])}
              name={`email[${i}]`}
              type="email"
            />
            <Form.Control.Feedback type="invalid">
              {/* {touched.email?.[i] && errors.email?.[i]} */}
            </Form.Control.Feedback>
            <Button
              disabled={isSubmitting}
              onClick={() =>
                setValues({
                  ...values,
                  email: [...values.email.filter((e) => e !== values.email[i])],
                })
              }
            >
              Remove
            </Button>
          </Form.Group>
        ))}

        <Button
          disabled={isSubmitting}
          onClick={() => setValues({ ...values, email: [...values.email, ""] })}
        >
          Add Another player
        </Button>
        <Button disabled={isSubmitting} type="submit">
          Submit
        </Button>
      </Form>
    </React.Fragment>
  );
};

export default AddNewLeaguePage;
