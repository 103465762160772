import * as React from "react";
import { Row, Col } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import Decks from "./Components/Decks";
import DeckSmall from "./Components/DeckSmall";
import MetagameSummary from "./Components/MetagameSummary";
import Pinnable from "./Components/Pinnable";
import MostPlayedCards from "./Components/MostPlayedCards";

import { loadEventDecks } from "./redux/decksReducer";
import {
  eventMetagameSummarySelector,
  selectedEventDecksSelector,
  deckSelector2,
  eventMostPlayedCardsSelector,
} from "./redux/decksSelectors";
import { decksFeatureSelector, GlobalState } from "./redux/reducer";
import { Deck } from "./logic";
import { X } from "react-bootstrap-icons";
import { isLoadingCollectionSelector } from "./redux/collectionFeatureSelectors";
import { useAuth0 } from "@auth0/auth0-react";

const EventPage = () => {
  const dispatch = useDispatch();
  const params = useParams<{ event: string; date: string; format: string }>();
  const event = decodeURIComponent(params.event || "");
  const rawDate = parseInt(params.date);
  const date = React.useMemo(() => new Date(rawDate), [rawDate]);
  const format = decodeURIComponent(params.format || "");
  const decks = useSelector((state: GlobalState) =>
    selectedEventDecksSelector(state, params)
  );
  const [selectedDeck, setSelectedDeck] = React.useState<Deck | undefined>();
  const { isLoading } = useSelector(decksFeatureSelector);
  const isLoadingCollection = useSelector(isLoadingCollectionSelector);
  const eventMetagame = useSelector((state: GlobalState) =>
    eventMetagameSummarySelector(state, params)
  );
  const cardSummary = useSelector((state: GlobalState) =>
    eventMostPlayedCardsSelector(state, params)
  );
  const { isAuthenticated } = useAuth0();

  const myDeck = useSelector((state: GlobalState) =>
    deckSelector2(state, selectedDeck)
  );

  const changeDeck = (deck?: Deck) => {
    setSelectedDeck(deck);
    setSelectedCard(undefined);
  };
  const [selectedCard, setSelectedCard] = React.useState<string | undefined>();

  React.useEffect(() => {
    dispatch(loadEventDecks(event, date, format));
  }, [event, date, format, dispatch]);

  React.useEffect(() => {
    document.title = `MTG Results - ${event}`;
  }, [event]);

  return (
    <React.Fragment>
      <Row>
        <Col>
          <h1>{`${event} ${date.toLocaleDateString("en-US")}`}</h1>
        </Col>
      </Row>
      <Row>
        <Col>
          <MetagameSummary
            isLoading={isLoading}
            format={format}
            metagameSummary={eventMetagame}
            showFooter={false}
          />
          <MostPlayedCards isLoading={isLoading} cardSummary={cardSummary} />
        </Col>
        <Col>
          <Decks
            isLoading={isLoading}
            type="small"
            decks={decks}
            onDeckSelected={(d) => changeDeck(d)}
            selectedDeck={selectedDeck}
          />
        </Col>
        {selectedDeck && (
          <Col>
            <Pinnable>
              <div>
                <X
                  style={{ cursor: "pointer" }}
                  onClick={() => changeDeck(undefined)}
                />
              </div>
              <DeckSmall
                isSignedIn={isAuthenticated}
                isLoadingCollection={isLoadingCollection}
                deck={myDeck}
                format={format}
                selectedCard={selectedCard}
                setSelectedCard={setSelectedCard}
              />
            </Pinnable>
          </Col>
        )}
      </Row>
    </React.Fragment>
  );
};

export default EventPage;
