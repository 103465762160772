import * as React from "react";
import { DeckWithCollectionData, summarizeDeck } from "../logic";
import scryfallGateway from "../scryfallGateway";
import { Table } from "react-bootstrap";
import Skeleton from "./Skeleton";

interface CardsNeededProps {
  isLoading: boolean;
  deck: DeckWithCollectionData;
}

const CardsNeeded = ({ isLoading, deck }: CardsNeededProps) => {
  const [totalCost, setTotalCost] = React.useState(0);
  const [pricedCards, setPricedCards] = React.useState<
    Array<{
      name: string;
      price: number;
      count: number;
      amountInCollection: number;
    }>
  >([]);
  const [totalCardsNeeded, setTotalCardsNeeded] = React.useState(0);
  const [isLoadingPrices, setIsLoadingPrices] = React.useState(false);

  //TODO: cleanup
  React.useEffect(() => {
    if (!deck) return;
    setIsLoadingPrices(true);
    (async () => {
      const summarizedDeck = summarizeDeck(deck);
      const missingCards = summarizedDeck.filter(
        (c) => c.count - c.amountInCollection > 0
      );
      const getCost = async (card: {
        name: string;
        count: number;
        amountInCollection: number;
      }) => {
        const cardData = await scryfallGateway.getCard(card.name);
        return { ...card, price: cardData.prices.usd || 0 };
      };
      const pricedCards = await Promise.all(
        missingCards.map((c) => getCost(c))
      );

      setPricedCards(pricedCards);
      const totalCost = pricedCards.reduce((a, i) => {
        return a + i.price * (i.count - i.amountInCollection);
      }, 0);

      setTotalCost(totalCost);
      setTotalCardsNeeded(
        missingCards.reduce((a, i) => a + (i.count - i.amountInCollection), 0)
      );
      setIsLoadingPrices(false);
    })();
  }, [deck]);

  return (
    <React.Fragment>
      <h1>Cards Needed</h1>
      <Table>
        <thead>
          <tr>
            <th>Card</th>
            <th>Qty Needed</th>
            <th>Unit Price</th>
            <th>Total Price</th>
          </tr>
        </thead>
        <tbody>
          {isLoading ||
            (isLoadingPrices && (
              <tr>
                <td>
                  <Skeleton count={10} />
                </td>
                <td>
                  <Skeleton count={10} />
                </td>
                <td>
                  <Skeleton count={10} />
                </td>
                <td>
                  <Skeleton count={10} />
                </td>
              </tr>
            ))}
          {!isLoading &&
            !isLoadingPrices &&
            pricedCards.map((p, index: number) => (
              <tr key={index}>
                <td>{p.name}</td>
                <td>{p.count - p.amountInCollection}</td>
                <td>${p.price}</td>
                <td>
                  ${((p.count - p.amountInCollection) * p.price).toFixed(2)}
                </td>
              </tr>
            ))}
        </tbody>
        <tfoot>
          <tr>
            <th>Total # of Cards</th>
            <th>{totalCardsNeeded}</th>
            <th>Total cost</th>
            <th>${totalCost.toFixed(2)}</th>
          </tr>
        </tfoot>
      </Table>
    </React.Fragment>
  );
};

export default CardsNeeded;
