import * as React from "react";
import { useParams } from "react-router-dom";
import gateway, {
  LeagueDetails,
  LeagueMatch,
  LeagueMatchResult,
} from "./apiGateway";
import Loader from "./Components/Loader";
import { Link } from "react-router-dom";
import { Row, Col, Table } from "react-bootstrap";
import { useAuth0 } from "@auth0/auth0-react";

const LeagueMatchesPage = () => {
  const { user } = useAuth0();
  const { leagueId } = useParams<{ leagueId: string }>();
  const [isLoading, setIsLoading] = React.useState(true);
  const [currentError, setCurrentError] = React.useState<string>();
  const [matches, setMatches] = React.useState<Array<LeagueMatch>>([]);
  const [league, setLeague] = React.useState<LeagueDetails>();
  const [matchResults, setMatchResults] = React.useState<
    Array<LeagueMatchResult>
  >([]);
  const [matchResultsLoadedSuccessfully, setMatchResultsLoadedSuccessfully] =
    React.useState(false);

  React.useEffect(() => {
    (async () => {
      try {
        const matchesFromApi = await gateway.loadLeagueMatches(leagueId);

        setMatches(matchesFromApi);

        const leagueFromApi = await gateway.loadLeagueDetails(leagueId);
        setLeague(leagueFromApi);
      } catch (err) {
        if (err instanceof Error) {
          setCurrentError(err.message);
        }
        if (typeof err === "string") {
          setCurrentError(err);
        }
      } finally {
        setIsLoading(false);
      }
    })();
  }, [leagueId]);

  React.useEffect(() => {
    (async () => {
      const r = await gateway.loadLeagueResults(leagueId);
      setMatchResults(r);
      setMatchResultsLoadedSuccessfully(true);
    })();
  }, [matches, leagueId]);

  React.useEffect(() => {
    document.title = `MTG Results - ${league?.name} Matches`;
  }, [league]);

  const matchesByRound = matches
    .reduce((acc, curr) => {
      let item = acc.find((a) => a.round === curr.round);
      if (!item) {
        item = { round: curr.round, matches: [] };
        acc.push(item);
      }
      item.matches.push(curr);
      return acc;
    }, new Array<{ round: number; matches: Array<LeagueMatch> }>())
    .sort((a, b) => {
      if (a.round > b.round) return 1;
      if (b.round > a.round) return -1;
      return 0;
    });

  const isMatchParticipant = (match: LeagueMatch) => {
    return (
      user?.sub === match.player1GoogleId || user?.sub === match.player2GoogleId
    );
  };

  const standings = matchResults.reduce((a, b) => {
    const currentMatch = matches.find((m) => m.matchId === b.matchId);
    let player1 = a.find(
      (m) => m.playerGoogleId === currentMatch?.player1GoogleId
    );
    if (!player1) {
      player1 = {
        playerGoogleId: currentMatch?.player1GoogleId || "",
        playerName: currentMatch?.player1Name || "",
        wins: 0,
        losses: 0,
        draws: 0,
      };
    }
    let player2 = a.find(
      (m) => m.playerGoogleId === currentMatch?.player2GoogleId
    );
    if (!player2) {
      player2 = {
        playerGoogleId: currentMatch?.player2GoogleId || "",
        playerName: currentMatch?.player2Name || "",
        wins: 0,
        losses: 0,
        draws: 0,
      };
    }

    if (b.player1Wins > b.player2Wins) {
      player1.wins++;
      player2.losses++;
    } else if (b.player2Wins > b.player1Wins) {
      player1.losses++;
      player2.wins++;
    } else {
      player1.draws++;
      player2.draws++;
    }

    const x = a.filter(
      (m) =>
        m.playerGoogleId !== (player1?.playerGoogleId || "") &&
        m.playerGoogleId !== (player2?.playerGoogleId || "")
    );

    return [...x, player1, player2].sort((a, b) => {
      if (b.wins > a.wins) return 1;
      if (a.wins > b.wins) return -1;
      return 0;
    });
  }, new Array<{ playerGoogleId: string; playerName: string; wins: number; losses: number; draws: number }>());

  return (
    <React.Fragment>
      <Loader isLoading={isLoading} error={currentError}>
        <h1>{league?.name}</h1>
        <p>
          <Link to={`/leagues/${leagueId}`}>Back to league</Link>
        </p>
        <Row>
          <Col xs={12} lg={6}>
            <h2>Pairings</h2>
            {matchesByRound.map((r) => (
              <React.Fragment key={r.round}>
                <h3>Round {r.round}</h3>
                {r.matches.map((m) => {
                  const results = matchResults.find(
                    (r) => r.matchId === m.matchId
                  );
                  const player1Wins = results ? `(${results.player1Wins})` : ``;
                  const player2Wins = results ? `(${results.player2Wins})` : ``;
                  return (
                    <p key={m.matchId}>
                      {`${m.player1Name} ${player1Wins} vs. ${m.player2Name} ${player2Wins}`}
                      {matchResultsLoadedSuccessfully &&
                        !results &&
                        isMatchParticipant(m) && (
                          <Link
                            to={`/leagues/${leagueId}/matches/${m.matchId}/addResults`}
                          >
                            Add Match Results
                          </Link>
                        )}
                    </p>
                  );
                })}
              </React.Fragment>
            ))}
          </Col>
          <Col xs={12} lg={6}>
            <h2>Standings</h2>
            <Table>
              <thead>
                <tr>
                  <th>Player</th>
                  <th>Wins</th>
                  <th>Losses</th>
                  <th>Draws</th>
                </tr>
              </thead>
              <tbody>
                {standings.map((s) => (
                  <tr key={s.playerGoogleId}>
                    <td>{s.playerName}</td>
                    <td>{s.wins}</td>
                    <td>{s.losses}</td>
                    <td>{s.draws}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      </Loader>
    </React.Fragment>
  );
};

export default LeagueMatchesPage;
