import * as React from "react";
import { OverlayTrigger } from "react-bootstrap";
import { CardDisplay } from "./CardDisplay";
import Skeleton from "./Skeleton";
// import { Link } from "react-router-dom";

interface MostPlayedCardsProps {
  isLoading: boolean;
  cardSummary: Array<{
    name: string;
    qty: number;
    mincount: number;
    maxcount: number;
    numberOfDecks: number;
    percentageOfDecks: number;
  }>;
}

const MostPlayedCards = ({ isLoading, cardSummary }: MostPlayedCardsProps) => {
  const [selectedCard, setSelectedCard] = React.useState<string>();

  return (
    <React.Fragment>
      <h2>Most Played Cards</h2>
      <table className="table">
        <thead>
          <tr>
            <th>Name</th>
            <th># of Copies</th>
            <th>% of Decks</th>
          </tr>
        </thead>
        <tbody>
          {isLoading && (
            <tr>
              <td>
                <Skeleton count={10} />
              </td>
              <td>
                <Skeleton count={10} />
              </td>
              <td>
                <Skeleton count={10} />
              </td>
            </tr>
          )}
          {!isLoading &&
            cardSummary &&
            cardSummary.map((s) => (
              <tr key={s.name} onMouseOver={() => setSelectedCard(s.name)}>
                <td>
                  {/* <Link to={`/card/${s.name}`}> */}
                  {selectedCard === s.name ? (
                    <OverlayTrigger
                      trigger={["hover", "focus"]}
                      key={`popover${selectedCard}`}
                      placement="right"
                      overlay={
                        <div>
                          <CardDisplay selectedCard={s.name} />
                        </div>
                      }
                    >
                      <span>{s.name}</span>
                    </OverlayTrigger>
                  ) : (
                    <span>{s.name}</span>
                  )}
                  {/* </Link> */}
                </td>
                <td>{s.qty}</td>
                <td>{(s.percentageOfDecks * 100).toFixed(2)}%</td>
              </tr>
            ))}
        </tbody>
      </table>
    </React.Fragment>
  );
};

export default MostPlayedCards;
