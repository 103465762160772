import { combineReducers } from "redux";

import { decksReducer } from "./decksReducer";
import { decksFeatureReducer, DecksFeatureState } from "./decksFeatureReducer";
import { collectionReducer } from "./collectionReducer";
import {
  collectionFeatureReducer,
  CollectionFeatureState,
} from "./collectionFeatureReducer";
import {
  decksIOwnFeatureReducer,
  DecksIOwnFeatureState,
} from "./decksIOwnFeatureReducer";

import type { Collection, Deck } from "../logic";

export default combineReducers({
  decks: decksReducer,
  decksFeature: decksFeatureReducer,
  collection: collectionReducer,
  collectionFeature: collectionFeatureReducer,
  decksIOwnFeature: decksIOwnFeatureReducer,
});

export interface GlobalState {
  decks: Deck[];
  decksFeature: DecksFeatureState;
  collection: Collection[];
  collectionFeature: CollectionFeatureState;
  decksIOwnFeature: DecksIOwnFeatureState;
}

export const decksSelector = (globalState: GlobalState): Array<Deck> =>
  globalState.decks;
export const decksFeatureSelector = (
  globalState: GlobalState
): DecksFeatureState => globalState.decksFeature;
export const collectionsSelector = (
  globalState: GlobalState
): Array<Collection> => globalState.collection;
export const collectionFeatureSelector = (
  globalState: GlobalState
): CollectionFeatureState => globalState.collectionFeature;
export const decksIOwnFeatureSelector = (
  globalState: GlobalState
): DecksIOwnFeatureState => globalState.decksIOwnFeature;
