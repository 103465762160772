import * as React from "react";
import DeckSection from "./DeckSection";
import { Link, generatePath } from "react-router-dom";
import Skeleton from "./Skeleton";
import { Clipboard } from "react-bootstrap-icons";
import { Tooltip, OverlayTrigger } from "react-bootstrap";

import type { DeckWithCollectionData } from "../logic";

interface DeckDisplayProps {
  isLoadingCollection: boolean;
  isLoading?: boolean;
  deck: DeckWithCollectionData;
  format: string;
  isSignedIn: boolean;
  selectedCard?: string;
  selectCard?: (name: string) => void;
  showLink?: boolean;
}

const DeckDisplay = ({
  isLoadingCollection = false,
  isLoading,
  deck,
  format,
  isSignedIn = false,
  selectedCard,
  selectCard = () => {
    /*do nothing*/
  },
  showLink = true,
}: DeckDisplayProps) => {
  const { archetype, owner, mainboard, sideboard, _id } = deck;

  const copyToClipboard = (deck: DeckWithCollectionData) => {
    const arenaDeck = deck.mainboard
      .map((c) => `${c.count} ${c.name}`)
      .concat([""])
      .concat(deck.sideboard.map((c) => `${c.count} ${c.name}`))
      .join("\r\n");
    navigator.clipboard.writeText(arenaDeck);
  };

  return (
    <React.Fragment>
      <h2>
        {!isLoading && (
          <React.Fragment>
            {showLink && (
              <Link
                to={generatePath(
                  "/:format(modern|legacy|pauper|pioneer)/deck/:id",
                  {
                    format: format,
                    id: _id,
                  }
                )}
              >
                {archetype}
              </Link>
            )}
            {!showLink && <p>{archetype}</p>}
          </React.Fragment>
        )}
        {isLoading && <Skeleton count={1} />}
      </h2>
      by {isLoading && <Skeleton count={1} />}
      {!isLoading && <React.Fragment>{owner}</React.Fragment>}
      <div>
        <OverlayTrigger
          placement="right"
          delay={{ show: 250, hide: 400 }}
          overlay={(props) => (
            <Tooltip id="copy" {...props}>
              Copy to clipboard
            </Tooltip>
          )}
        >
          <Clipboard
            style={{ cursor: "pointer" }}
            onClick={() => copyToClipboard(deck)}
          />
        </OverlayTrigger>
      </div>
      <DeckSection
        isLoading={isLoading}
        title="Mainboard"
        data={mainboard}
        selectCard={(c) => selectCard(c)}
        isSignedIn={isSignedIn}
        isLoadingCollection={isLoadingCollection}
        selectedCard={selectedCard}
      />
      <DeckSection
        isLoading={isLoading}
        title="Sideboard"
        data={sideboard}
        selectCard={(c) => selectCard(c)}
        propToUseForTotalCount="totalAmountInDeck"
        isSignedIn={isSignedIn}
        isLoadingCollection={isLoadingCollection}
        selectedCard={selectedCard}
      />
    </React.Fragment>
  );
};

export default DeckDisplay;
