import * as React from "react";
import apiGateway from "./apiGateway";
import {
  getDaysAgo,
  getDeckWithCollectionData,
  summarizeMetagame,
} from "./logic";
import { useDispatch, useSelector } from "react-redux";
import { Form, Row, Col, Card } from "react-bootstrap";
import Decks from "./Components/Decks";
import Deck from "./Components/Deck";
import Pinnable from "./Components/Pinnable";
import MetagameSummary from "./Components/MetagameSummary";
import { reducedCollectionSelector } from "./redux/collectionSelectors";
import {
  isLoadingCollectionSelector,
  selectedCollectionNameSelector,
} from "./redux/collectionFeatureSelectors";
import CollectionButtons from "./Components/CollectionButtons";
import { Deck as DeckData } from "./logic";
import { X } from "react-bootstrap-icons";
import {
  updateSelectedFormat,
  updateIncludeLeagues,
  updateMaximumMissingCards,
} from "./redux/decksIOwnFeatureReducer";
import {
  selectedFormatSelector,
  includeLeaguesSelector,
  maximumMissingCardsSelector,
} from "./redux/decksIOwnFeatureSelectors";

const DecksIOwnPage = () => {
  const dispatch = useDispatch();
  const selectedFormat = useSelector(selectedFormatSelector);
  const includeLeagues = useSelector(includeLeaguesSelector);
  const maximumMissingCards = useSelector(maximumMissingCardsSelector);
  const [decks, setDecks] = React.useState(new Array<DeckData>());
  const [originalDecks, setOriginalDecks] = React.useState(
    new Array<DeckData>()
  );
  const startDate = React.useMemo(() => getDaysAgo(7), []);
  const [isLoading, setIsLoading] = React.useState(false);
  const [selectedDeck, setSelectedDeck] = React.useState<DeckData>();
  const [selectedCard, setSelectedCard] = React.useState<string>();
  const [metagame, setMetagame] = React.useState<
    Array<{ percentage: number; archetype: string; count: number }>
  >([]);
  const collection = useSelector(reducedCollectionSelector);
  const collectionName = useSelector(selectedCollectionNameSelector);
  const isLoadingCollection = useSelector(isLoadingCollectionSelector);

  React.useEffect(() => {
    (async () => {
      setIsLoading(true);
      try {
        const decks = await apiGateway.loadDecksByDate(
          selectedFormat.toLowerCase(),
          startDate
        );

        setOriginalDecks(decks);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [startDate, selectedFormat]);

  React.useEffect(() => {
    const decksWithCollection = originalDecks.map((d) =>
      getDeckWithCollectionData(d, collection)
    );

    const filteredDecks = decksWithCollection.filter(
      (d) =>
        d.totalCardsNeeded <= maximumMissingCards &&
        (includeLeagues || !d.event.toLowerCase().includes("league"))
    );

    const metagame = summarizeMetagame(filteredDecks);
    setMetagame(metagame);
    setDecks(filteredDecks);
  }, [collection, originalDecks, includeLeagues, maximumMissingCards]);

  const getQualifier = (maximumMissingCards: number) =>
    maximumMissingCards > 0 ? "(kinda)" : "";

  return (
    <div>
      <Row>
        <Col>
          <Card>
            <Card.Body>
              <Card.Title>Filters</Card.Title>
              <Form.Label>Format:</Form.Label>
              <Form.Control
                as="select"
                value={selectedFormat}
                onChange={(e) => {
                  setSelectedDeck(undefined);
                  setDecks([]);
                  dispatch(updateSelectedFormat(e.target.value));
                }}
              >
                <option>Modern</option>
                <option>Pauper</option>
                <option>Legacy</option>
                <option>Pioneer</option>
              </Form.Control>
              <Form.Label>Maximum Missing Cards:</Form.Label>
              <Form.Control
                as="select"
                value={maximumMissingCards}
                onChange={(e) => {
                  setSelectedDeck(undefined);
                  setDecks([]);
                  dispatch(
                    updateMaximumMissingCards(parseInt(e.target.value, 10))
                  );
                }}
              >
                <option>0</option>
                <option>1</option>
                <option>2</option>
                <option>3</option>
                <option>4</option>
                <option>5</option>
              </Form.Control>
              <Form.Check
                type="checkbox"
                label="Include leagues"
                checked={includeLeagues}
                onChange={(e) => {
                  dispatch(updateIncludeLeagues(e.target.checked));
                }}
              />
            </Card.Body>
          </Card>
          <CollectionButtons />
          <h1>{`${selectedFormat} Decks I Own ${getQualifier(
            maximumMissingCards
          )} in ${collectionName} from ${new Intl.DateTimeFormat("en").format(
            startDate
          )}`}</h1>
        </Col>
      </Row>
      <Row>
        <Col>
          <MetagameSummary
            isLoading={isLoading}
            metagameSummary={metagame}
            format={selectedFormat}
            showFooter={true}
          />
        </Col>
        <Col>
          <Decks
            isLoading={isLoading}
            decks={decks}
            onDeckSelected={(d) => setSelectedDeck(d)}
            selectedDeck={selectedDeck}
          />
        </Col>
        {selectedDeck && (
          <Col>
            <Pinnable>
              <div>
                <X
                  style={{ cursor: "pointer" }}
                  onClick={() => setSelectedDeck(undefined)}
                />
              </div>
              <Deck
                deck={getDeckWithCollectionData(selectedDeck, collection)}
                format={selectedFormat}
                selectedCard={selectedCard}
                selectCard={(c) => setSelectedCard(c)}
                isSignedIn={true}
                isLoadingCollection={isLoadingCollection}
              />
            </Pinnable>
          </Col>
        )}
      </Row>
    </div>
  );
};

export default DecksIOwnPage;
