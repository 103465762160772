import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import "bootstrap/dist/css/bootstrap.min.css";
import { Auth0Provider } from "@auth0/auth0-react";

import App from "./App";
import createStore from "./redux/store";
import "./index.css";

ReactDOM.render(
  <Provider store={createStore()}>
    <Auth0Provider
      domain={process.env["REACT_APP_AUTH0_DOMAIN"] ?? ""}
      clientId={process.env["REACT_APP_AUTH0_CLIENTID"] ?? ""}
      redirectUri={window.location.origin}
      audience={process.env["REACT_APP_AUTH0_AUDIENCE"] ?? ""}
      scope="openid profile email edit:deckname create:league"
    >
      <App />
    </Auth0Provider>
  </Provider>,
  document.getElementById("root")
);
