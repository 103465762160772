import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Row, Col } from "react-bootstrap";
import { useParams } from "react-router-dom";

import Events from "./Components/Events";
import MetagameSummary from "./Components/MetagameSummary";
import MostPlayedCards from "./Components/MostPlayedCards";

import { loadDecks } from "./redux/decksReducer";
import {
  formatMetagameSummarySelector,
  eventsSelector,
  formatMostPlayedCardsSelector,
} from "./redux/decksSelectors";
import { includeDateSelector } from "./redux/decksFeatureSelectors";
import { decksFeatureSelector } from "./redux/reducer";

const FormatPage = () => {
  const startDate = useSelector(includeDateSelector);
  const dispatch = useDispatch();
  const { format }: { format: string } = useParams();

  const formatMetagame = useSelector(formatMetagameSummarySelector);
  const events = useSelector(eventsSelector);
  const { isLoading } = useSelector(decksFeatureSelector);
  const cardSummary = useSelector(formatMostPlayedCardsSelector);

  React.useEffect(() => {
    if (format) {
      dispatch(loadDecks(format, startDate));
    }
  }, [format, startDate, dispatch]);

  React.useEffect(() => {
    if (format) {
      document.title = `MTG Results - ${format}`;
    }
  }, [format]);

  return (
    <Row>
      {format && (
        <React.Fragment>
          <Col>
            <MetagameSummary
              isLoading={isLoading}
              format={format}
              metagameSummary={formatMetagame}
            />
          </Col>
          <Col>
            <Events isLoading={isLoading} format={format} events={events} />
          </Col>
          <Col>
            <MostPlayedCards isLoading={isLoading} cardSummary={cardSummary} />
          </Col>
        </React.Fragment>
      )}
    </Row>
  );
};

export default FormatPage;
