import * as React from "react";
import { Row, Col, OverlayTrigger } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import DeckSmall from "./Components/DeckSmall";
import Decks from "./Components/Decks";
import Pinnable from "./Components/Pinnable";
import { Table } from "react-bootstrap";
import Skeleton from "./Components/Skeleton";

import {
  archetypeDecksSelector,
  deckSelector2,
  neededCardsSelector,
} from "./redux/decksSelectors";
import { decksFeatureSelector, GlobalState } from "./redux/reducer";
import { loadArchetypeDecks } from "./redux/decksReducer";
import { Deck } from "./logic";
import { X } from "react-bootstrap-icons";
import { isLoadingCollectionSelector } from "./redux/collectionFeatureSelectors";
import { useAuth0 } from "@auth0/auth0-react";
import { CardDisplay } from "./Components/CardDisplay";

const ArchetypePage = () => {
  const { isAuthenticated } = useAuth0();
  const dispatch = useDispatch();
  const params = useParams<{ archetype: string; format: string }>();
  const { archetype, format } = params;
  const [selectedDeck, setSelectedDeck] = React.useState<Deck>();
  const decks = useSelector((state: GlobalState) =>
    archetypeDecksSelector(state, params)
  );
  const { isLoading } = useSelector(decksFeatureSelector);
  const myDeck = useSelector((state: GlobalState) =>
    deckSelector2(state, selectedDeck)
  );
  const changeDeck = (deck: Deck | undefined) => {
    setSelectedDeck(deck);
    setSelectedCard(undefined);
  };
  const [selectedCard, setSelectedCard] = React.useState<string>();
  const [otherSelectedCard, setOtherSelectedCard] = React.useState<string>();

  const isLoadingCollection = useSelector(isLoadingCollectionSelector);

  const neededCards = useSelector((state: GlobalState) =>
    neededCardsSelector(state, params)
  );

  React.useEffect(() => {
    dispatch(loadArchetypeDecks(archetype, format));
  }, [archetype, format, dispatch]);

  React.useEffect(() => {
    document.title = `MTG Results - ${format} ${archetype}`;
  }, [format, archetype]);

  return (
    <React.Fragment>
      <Row>
        <Col>
          <h1>{archetype}</h1>
        </Col>
      </Row>
      <Row>
        <Col>
          <Decks
            isLoading={isLoading}
            decks={decks}
            onDeckSelected={(d) => changeDeck(d)}
            selectedDeck={selectedDeck}
          />
          <h2>Cards</h2>
          <Table>
            <thead>
              <tr>
                <th>Name</th>
                <th>% Decks</th>
                <th>Max Played</th>
              </tr>
            </thead>
            <tbody>
              {isLoading && (
                <tr>
                  <td>
                    <Skeleton count={20} />
                  </td>
                  <td>
                    <Skeleton count={20} />
                  </td>
                  <td>
                    <Skeleton count={20} />
                  </td>
                </tr>
              )}
              {!isLoading &&
                neededCards.map((c) => (
                  <tr
                    key={c.name}
                    className={
                      isAuthenticated &&
                      !isLoadingCollection &&
                      c.numberNeeded > 0 &&
                      c.numberInCollection === 0
                        ? "missing"
                        : isAuthenticated &&
                          !isLoadingCollection &&
                          c.numberNeeded > 0 &&
                          c.numberInCollection > 0
                        ? "partial"
                        : ""
                    }
                    onMouseOver={() => setOtherSelectedCard(c.name)}
                  >
                    <td>
                      {otherSelectedCard === c.name ? (
                        <OverlayTrigger
                          trigger={["hover", "focus"]}
                          key={`popover${otherSelectedCard}`}
                          placement="right"
                          overlay={
                            <div>
                              <CardDisplay selectedCard={c.name} />
                            </div>
                          }
                        >
                          <span>{c.name}</span>
                        </OverlayTrigger>
                      ) : (
                        <span>{c.name}</span>
                      )}
                    </td>
                    <td>{c.percentageOfDecks}</td>
                    <td>
                      {c.maxcount}

                      {isAuthenticated &&
                        !isLoadingCollection &&
                        c.numberNeeded > 0 && (
                          <span>
                            (-
                            {Math.min(c.numberNeeded, c.maxcount)})
                          </span>
                        )}
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </Col>
        {selectedDeck && (
          <Col>
            <Pinnable>
              <div>
                <X
                  style={{ cursor: "pointer" }}
                  onClick={() => changeDeck(undefined)}
                />
              </div>
              <DeckSmall
                deck={myDeck}
                format={format}
                selectedCard={selectedCard}
                setSelectedCard={setSelectedCard}
                isSignedIn={isAuthenticated}
                isLoadingCollection={isLoadingCollection}
              />
            </Pinnable>
          </Col>
        )}
      </Row>
    </React.Fragment>
  );
};

export default ArchetypePage;
