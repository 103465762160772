import * as React from "react";
import { Form, Button, Spinner, Alert } from "react-bootstrap";
import Loader from "./Components/Loader";
import { useParams } from "react-router-dom";
import gateway, { PlayerDeck } from "./apiGateway";

const LeagueAddMatchPage = () => {
  const [players, setPlayers] = React.useState(new Array<PlayerDeck>());
  const [currentError, setCurrentError] = React.useState<string>();
  const [isLoading, setIsLoading] = React.useState(true);
  const { leagueId } = useParams<{ leagueId: string }>();
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [player1, setPlayer1] = React.useState<string>();
  const [player2, setPlayer2] = React.useState<string>();
  const [round, setRound] = React.useState(1);
  const [errorAlertText, setErrorAlertText] = React.useState<string>();

  React.useEffect(() => {
    (async () => {
      try {
        const players = await gateway.loadLeagueDecks(leagueId);
        setPlayers(players);
        setPlayer1(players[0].playerObjectId);
        setPlayer2(players[1].playerObjectId);
        document.title = `MTG Results - Add Match`;
      } catch (err) {
        if (err instanceof Error) {
          setCurrentError(err.message);
        }
      } finally {
        setIsLoading(false);
      }
    })();
  }, [leagueId]);

  const submitMatch = async () => {
    setIsSubmitting(true);
    try {
      const matchData = {
        round: round,
        player1: player1,
        player2: player2,
      };
      await gateway.createMatch(leagueId, matchData);
    } catch (err) {
      if (err instanceof Error) {
        setErrorAlertText(err.message);
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <React.Fragment>
      {errorAlertText && (
        <Alert
          variant="danger"
          onClose={() => setErrorAlertText(undefined)}
          dismissible
        >
          <Alert.Heading>Could not create match</Alert.Heading>
          {errorAlertText}
        </Alert>
      )}
      <Loader isLoading={isLoading} error={currentError}>
        <h1>Add Match</h1>
        <Form>
          <Form.Group>
            <Form.Label>Round Number</Form.Label>
            <Form.Control
              type="text"
              defaultValue={round}
              onChange={(e) => setRound(parseInt(e.target.value))}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Player 1</Form.Label>
            <Form.Control
              as="select"
              defaultValue={player1}
              onChange={(e) => setPlayer1(e.target.value)}
            >
              {players.map((p) => (
                <option key={p.playerId} value={p.playerObjectId}>
                  {p.owner}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
          <Form.Group>
            <Form.Label>Player 2</Form.Label>
            <Form.Control
              as="select"
              defaultValue={player2}
              onChange={(e) => setPlayer2(e.target.value)}
            >
              {players.map((p) => (
                <option key={p.playerId} value={p.playerObjectId}>
                  {p.owner}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
          <Button onClick={() => submitMatch()}>
            <React.Fragment>
              {isSubmitting && (
                <React.Fragment>
                  <Spinner
                    as="span"
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                  Creating...
                </React.Fragment>
              )}
              {!isSubmitting && <React.Fragment>Create Match</React.Fragment>}
            </React.Fragment>
          </Button>
        </Form>
      </Loader>
    </React.Fragment>
  );
};

export default LeagueAddMatchPage;
