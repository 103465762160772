import * as React from "react";
import { useParams } from "react-router-dom";
import { Row, Col, Form, Card } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";

import apiGateway from "./apiGateway";
import Decks from "./Components/Decks";
import DeckSmall from "./Components/DeckSmall";
import Pinnable from "./Components/Pinnable";
import { reducedCollectionSelector } from "./redux/collectionSelectors";
import { getDeckWithCollectionData, Deck } from "./logic";
import { X } from "react-bootstrap-icons";
import { isLoadingCollectionSelector } from "./redux/collectionFeatureSelectors";

const CardPage = () => {
  const { isAuthenticated } = useAuth0();
  const { name } = useParams<{ name: string }>();
  const decodedName = React.useMemo(() => decodeURIComponent(name), [name]);
  const [decks, setDecks] = React.useState<Array<Deck>>([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [selectedDeck, setSelectedDeck] = React.useState<Deck>();
  const [selectedCard, setSelectedCard] = React.useState<string>();
  const [selectedFormat, setSelectedFormat] = React.useState<string>();
  const [selectedArchetype, setSelectedArchetype] = React.useState<string>();

  const collection = useSelector(reducedCollectionSelector);
  const isLoadingCollection = useSelector(isLoadingCollectionSelector);

  React.useEffect(() => {
    document.title = `MTG Results - ${decodedName}`;
    (async () => {
      setIsLoading(true);
      const decksFromApi = await apiGateway.getDecksContainingCard(decodedName);
      setDecks(decksFromApi);
      setIsLoading(false);
    })();
  }, [decodedName]);

  let filteredDecks = decks;

  if (selectedFormat) {
    filteredDecks = filteredDecks.filter(
      (d) => d.format === selectedFormat?.toLowerCase()
    );
  }

  if (selectedArchetype) {
    filteredDecks = filteredDecks.filter(
      (d) => d.archetype === selectedArchetype
    );
  }

  return (
    <React.Fragment>
      <Row>
        <Col>
          <Card>
            <Card.Body>
              <Card.Title>Filters</Card.Title>
              <Form.Group>
                <Form.Label>Format:</Form.Label>
                <Form.Control
                  as="select"
                  disabled={isLoading}
                  value={selectedFormat}
                  onChange={(e) => {
                    setSelectedFormat(e.target.value);
                    setSelectedDeck(undefined);
                    setSelectedArchetype(undefined);
                  }}
                >
                  <option value={undefined}></option>
                  <option>Legacy</option>
                  <option>Modern</option>
                  <option>Pauper</option>
                  <option>Pioneer</option>
                </Form.Control>
              </Form.Group>
              <Form.Group>
                <Form.Label>Archetype:</Form.Label>
                <Form.Control
                  as="select"
                  disabled={isLoading}
                  value={selectedArchetype}
                  onChange={(e) => {
                    setSelectedArchetype(e.target.value);
                    setSelectedDeck(undefined);
                  }}
                >
                  <option value={undefined}></option>
                  {[
                    ...Array.from(
                      new Set(filteredDecks.map((d) => d.archetype))
                    ),
                  ].map((archetype) => (
                    <option key={archetype}>{archetype}</option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col>
          <h1>Decks Playing {decodedName}</h1>
        </Col>
      </Row>
      <Row>
        <Col lg={selectedDeck ? 9 : 12}>
          <Decks
            showFormat={true}
            isLoading={isLoading}
            type="big"
            decks={filteredDecks}
            onDeckSelected={(d) => setSelectedDeck(d)}
            selectedDeck={selectedDeck}
          />
        </Col>
        {selectedDeck && (
          <Col lg="3">
            <Pinnable>
              <div>
                <X
                  style={{ cursor: "pointer" }}
                  onClick={() => setSelectedDeck(undefined)}
                />
              </div>
              <DeckSmall
                deck={getDeckWithCollectionData(selectedDeck, collection)}
                format={selectedDeck.format}
                selectedCard={selectedCard}
                setSelectedCard={setSelectedCard}
                isSignedIn={isAuthenticated}
                isLoadingCollection={isLoadingCollection}
              />
            </Pinnable>
          </Col>
        )}
      </Row>
    </React.Fragment>
  );
};

export default CardPage;
