import { createSelector } from "reselect";
import { decksFeatureSelector } from "./reducer";
import { getDaysAgo } from "../logic";

export const includeLeaguesSelector = createSelector(
  decksFeatureSelector,
  (decksFeature) => decksFeature.includeLeagues
);

export const daysAgoSelector = createSelector(
  decksFeatureSelector,
  (decksFeature) => decksFeature.daysAgo
);

export const includeDateSelector = createSelector(daysAgoSelector, (daysAgo) =>
  getDaysAgo(daysAgo)
);
