import { GlobalState } from "./reducer";

export const selectedOwnerSelector = (
  _: GlobalState,
  params: { owner: string }
) => {
  return params.owner;
};

export const selectedEventSelector = (
  _: GlobalState,
  params: { event: string; date: string }
) => {
  return {
    name: decodeURIComponent(params.event),
    date: new Date(parseInt(params.date)),
  };
};

export const selectedArchetypeSelector = (
  _: GlobalState,
  params: { archetype: string }
) => {
  return params.archetype;
};

export const selectedDeckSelector = (
  _: GlobalState,
  params: { id: string }
) => {
  return {
    id: params.id,
  };
};
