import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Card } from "react-bootstrap";

import {
  updateIncludeLeagues,
  updateDaysAgo,
} from "./redux/decksFeatureReducer";
import {
  includeLeaguesSelector,
  daysAgoSelector,
} from "./redux/decksFeatureSelectors";

const Format = ({ children }: { children: JSX.Element | JSX.Element[] }) => {
  const includeLeagues = useSelector(includeLeaguesSelector);
  const daysAgo = useSelector(daysAgoSelector);
  const dispatch = useDispatch();

  return (
    <React.Fragment>
      <Card>
        <Card.Body>
          <Card.Title>Filters</Card.Title>

          <Form.Check
            type="checkbox"
            label={`Include leagues`}
            checked={includeLeagues}
            onChange={(e) => {
              dispatch(updateIncludeLeagues(e.target.checked));
            }}
          />
          <Form.Label>From:</Form.Label>
          <Form.Control
            as="select"
            value={daysAgo.toString()}
            onChange={(e) => {
              dispatch(updateDaysAgo(parseInt(e.target.value)));
            }}
          >
            <option value={7}>last 7 days</option>
            <option value={14}>last 14 days</option>
            <option value={30}>last 30 days</option>
            <option value={90}>last 90 days</option>
            <option value={365}>last 365 days</option>
          </Form.Control>
        </Card.Body>
      </Card>
      {children}
    </React.Fragment>
  );
};

export default Format;
